import React from "react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

class ReactCarouselFloorPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onchange = this.onchange.bind(this);
  }

  onchange(value) {
    this.setState({ value });
  }

  componentDidMount() {}

  componentWillMount() {}

  render() {
    return (
      <div
        style={{
          maxWidth: "60vw",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexFlow: "row",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <Carousel
          slidesPerPage={1}
          value={this.state.value}
          onChange={this.onchange}
          // infinite
          keepDirectionWhenDragging
        >
          {this.props.slides.map((item) => {
            return (
              <div key={item.id} style={{ width: "100%" }}>
                <img
                  style={{
                    // width: "100%",
                    height: "70vh",
                    borderRadius: "0px",
                    objectFit: "cover",
                    padding: '50px'
                  }}
                  src={item.backgroundImage}
                  alt={item.altText}
                />
              </div>
            );
          })}
        </Carousel>

        {this.props.slides.length > 1 && 
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              width: "100%",
            }}
          >
            <Dots
              value={this.state.value} 
              onChange={this.onchange}
              number={this.props.slides.length}
            />
          </div>
        }
      </div>
    );
  }
}
export default ReactCarouselFloorPlan;
