import axios from 'axios';

const domain = window.document.domain;

let baseURL = "";

switch (domain) {
	case "broadmeadowparkapp.co.uk":
		baseURL = 'https://broadmeadowparkapp.co.uk/app/';
		break;
	case "broadmeadowpark.focus-pluto.co.uk":
		baseURL = 'https://broadmeadowpark.focus-pluto.co.uk/app/';
		break;
	default:
		baseURL = 'https://broadmeadowpark.focus-pluto.co.uk/app/';
		break;
}

const apiInstance = axios.create({
	baseURL: baseURL
});

export default apiInstance;
