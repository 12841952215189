import React, { Component } from "react";
import "./MapControls.css";

class MapControls extends Component {
  state = {
    transportChecked: true,
    foodChecked: true,
    educationChecked: true,
    fitnessChecked: true,
    entertainmentChecked: true,
    shopsChecked: true,
    showControls: false,
  };

  controlsSection = null;
  imgUrl = window.imageUrl;

  componentDidMount() {

    this.props.mounted();
  }

  handleTransportCheckboxChange = (event) => {
    this.setState({ transportChecked: event.target.checked });
    console.log(event.target.checked);
    this.props.showTransport(event.target.checked);
  };
  handleFoodCheckboxChange = (event) => {
    this.setState({ foodChecked: event.target.checked });
    console.log(event.target.checked);
    this.props.showFood(event.target.checked);
  };

  handleEducationCheckboxChange = (event) => {
    this.setState({ educationChecked: event.target.checked });
    console.log(event.target.checked);
    this.props.showEducation(event.target.checked);
  };
  handleFitnessCheckboxChange = (event) => {
    this.setState({ fitnessChecked: event.target.checked });
    console.log(event.target.checked);
    this.props.showFitness(event.target.checked);
  };

  handleEntertainmentCheckboxChange = (event) => {
    this.setState({ entertainmentChecked: event.target.checked });
    console.log(event.target.checked);
    this.props.showEntertainment(event.target.checked);
  };

  handleShopsCheckboxChange = (event) => {
    this.setState({ shopsChecked: event.target.checked });
    console.log(event.target.checked);
    this.props.showShops(event.target.checked);
  };

  toggleMapControls = (event) => {
    this.setState({ showControls: !this.state.showControls });
  };

  resetFilters = (event) => {
    this.setState({
      transportChecked: true,
      foodChecked: true,
      educationChecked: true,
      fitnessChecked: true,
      entertainmentChecked: true,
      shopsChecked: true,
    });

    this.props.resetControls();
  };

  render() {
    const transportIcon = this.imgUrl + this.props.imageIcons.transport_check_icon;
    const foodIcon = this.imgUrl + this.props.imageIcons.eating_out_check_icon;
    const educationIcon = this.imgUrl + this.props.imageIcons.education_check_icon;
    const fitnessIcon = this.imgUrl + this.props.imageIcons.fitness_check_icon;
    const entertainmentIcon = this.imgUrl + this.props.imageIcons.entertainment_check_icon;
    const shopsIcon = this.imgUrl + this.props.imageIcons.shopping_check_icon;
    const filterIcon = this.imgUrl + this.props.imageIcons.filter_icon;

    return (
      <div style={{ position: "fixed", bottom: "0", left: "46px", zIndex: "200", overflow: "hidden", width: "350px" }} >
        <div onClick={this.toggleMapControls} style={{ backgroundColor: "#34586b", padding: "20px", width: "100%", fontSize: '12px', fontWeight: 'bold'}}>
          <img
            className="actionContainer"
            onClick={this.toggleMapControls}
            src={filterIcon}
            alt=""
            style={{ width: "25px", height: "auto", marginRight: "10px", verticalAlign: "middle" }}
          />
          <span
            className="actionText"
            onClick={this.toggleMapControls}
            style={{ textTransform: "uppercase", fontSize: "18px", color: "#fff", marginLeft: "10px", verticalAlign: "middle" }}
          >
            <span className="body-GalanoGrotesque">Filter Map</span>
          </span>

          {this.state.showControls === true &&
            <span
              onClick={this.toggleMapControls}
              style={{
                color: "#fff",
                position: "absolute",
                right: "25px",
                cursor: "pointer",
                top: "20px"
              }}
            >
              <img src={require("assets/img/filter-close.svg")} alt="" />
            </span>
          }
        </div>

        <div className={(this.state.showControls === true ? 'activeFilter' : 'inactiveFilter') + ' filterViewport'} style={{ backgroundColor: "#fff" }}>
          <div style={{ padding: '15px', margin: '0px'}}>
            <span className="customcheck" style={{marginBottom: '10px'}}>
              <label className="customcheck body-GalanoGrotesque" style={{ marginLeft: '60px', fontSize: '16px', color: '#34586b', marginBottom: '0' }}>
                <img
                  className="actionContainer"
                  src={transportIcon} 
                  alt=""
                  style={{ height: "auto", width: "45px", marginRight: "10px"}}
                />
                Transport
                  <input type="checkbox"
                  checked={this.state.transportChecked}
                  onChange={this.handleTransportCheckboxChange} className="customcheck" name="transport" value="yes"/>
                  <span className="customcheck-target" style={{marginTop: "10px"}}></span>
              </label>
            </span>
            <div style={{ padding: '0px 5px', margin: '0px' }}>
              <span className="customcheck" style={{marginBottom: '10px'}}>
                <label className="customcheck body-GalanoGrotesque" style={{ marginLeft: '60px', fontSize: '16px', color: '#34586b', marginBottom: '0' }}>
                  <img
                    className="actionContainer"
                    src={foodIcon} 
                    alt=""
                    style={{ height: "auto", width: "45px", marginRight: "10px"}}
                  />
                  Food &amp; drink
                  <input
                    type="checkbox"
                    checked={this.state.foodChecked}
                    onChange={this.handleFoodCheckboxChange} className="customcheck" name="food" value="yes" />
                  <span className="customcheck-target" style={{marginTop: "10px"}}></span>
                </label>
              </span>
            </div>
            <div style={{ padding: '0px 5px', margin: '0px' }}>
              <span className="customcheck" style={{marginBottom: '10px'}}>
                <label className="customcheck body-GalanoGrotesque" style={{ marginLeft: '60px', fontSize: '16px', color: '#34586b', marginBottom: '0' }}>
                <img
                    className="actionContainer"
                    src={educationIcon} 
                    alt=""
                    style={{ height: "auto", width: "45px", marginRight: "10px"}}
                  />
                  Education
                  <input                
                    type="checkbox"
                    checked={this.state.educationChecked}
                    onChange={this.handleEducationCheckboxChange} className="customcheck" name="education" value="yes" />
                  <span className="customcheck-target" style={{marginTop: "10px"}}></span>
                </label>
              </span>
            </div>
            <div style={{ padding: '0px 5px', margin: '0px' }}>
              <span className="customcheck" style={{marginBottom: '10px'}}>
                <label className="customcheck body-GalanoGrotesque" style={{ marginLeft: '60px', fontSize: '16px', color: '#34586b', marginBottom: '0' }}>
                <img
                    className="actionContainer"
                    src={fitnessIcon} 
                    alt=""
                    style={{ height: "auto", width: "45px", marginRight: "10px"}}
                  />
                  Fitness &amp; leisure
                  <input
                    type="checkbox"
                    checked={this.state.fitnessChecked}
                    onChange={this.handleFitnessCheckboxChange}
                    className="customcheck" name="fitness" value="yes"
                  />
                  <span className="customcheck-target" style={{marginTop: "10px"}}></span>
                </label>
              </span>
            </div>
            <div style={{ padding: '0px 5px', margin: '0px' }}>
              <span className="customcheck" style={{marginBottom: '10px'}}>
                <label className="customcheck body-GalanoGrotesque" style={{ marginLeft: '60px', fontSize: '16px', color: '#34586b', marginBottom: '0' }}>
                <img
                    className="actionContainer"
                    src={entertainmentIcon} 
                    alt=""
                    style={{ height: "auto", width: "45px", marginRight: "10px"}}
                  />
                  Entertainment
                  <input
                    type="checkbox"
                    checked={this.state.entertainmentChecked}
                    onChange={this.handleEntertainmentCheckboxChange}
                    className="customcheck" name="entertainment" value="yes"
                  />
                  <span className="customcheck-target" style={{marginTop: "10px"}}></span>
                </label>
              </span>
            </div>
            <div style={{ padding: '0px 5px', margin: '0px' }}>
              <span className="customcheck" style={{marginBottom: '10px'}}>
                <label className="customcheck body-GalanoGrotesque" style={{ marginLeft: '60px', fontSize: '16px', color: '#34586b', marginBottom: '0' }}>
                  <img
                    className="actionContainer"
                    src={shopsIcon} 
                    alt=""
                    style={{ height: "auto", width: "45px", marginRight: "10px"}}
                  />
                  Shop &amp; amenities
                  <input
                    type="checkbox"
                    checked={this.state.shopsChecked}
                    onChange={this.handleShopsCheckboxChange}
                    className="customcheck" name="shopping" value="yes"
                  />
                  
                  <span className="customcheck-target" style={{marginTop: "10px"}}></span>
                </label>
              </span>
            </div>
            <div style={{ padding: '0', margin: '25px 0px', textAlign: 'left' }}>
              <p
                className="actionText"
                onClick={this.resetFilters}
                style={{ textTransform: "uppercase", textDecoration: "underline", fontSize: "14px", fontWeight: 'bold' }}
              >
                <span className="body-GalanoGrotesque" style={{color: '#34586b'}}>Reset Filters</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapControls;
