import React from "react";
import "./Property.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShare, faTimes } from '@fortawesome/free-solid-svg-icons'
import * as actionCreators from "../../store/actions/index";
import { connect } from "react-redux";
import Modal from 'react-modal';
import { TextInputField, Spinner, Pane, Button, LinkIcon, ShareIcon } from 'evergreen-ui';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from "../../axios";

// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)',
//     width: '300px'
//   }
// };

// var subtitle;

class property extends React.Component {

  constructor(props){
    super(props);

    this.compareCheckedHandler = this.compareCheckedHandler.bind(this);
  }

  state = {
    // isModalOpen: false,
    // yourName: "",
    // yourEmail: "",
    // friendName: "",
    // friendEmail: "",
    // value: '',
    // copied: false,
    // showFormSubmitting: false
  };

  compareCheckedHandler(event) {
      if (event.target.checked === true) {
        this.props.compareChecked(this.props);
      }

      if (event.target.checked === false) {
        this.props.compareUnchecked(this.props);
      }
  };

  componentDidMount() {
    if (this.props.compareIndex == this.props.id) {
      document.getElementById("prop-check-" + this.props.id).click()
    }

    if (this.props.id !== undefined) {
      //this.setState({ value: 'http://localhost:3000/plot-overview/' + this.props.id });
      //Modal.setAppElement(document.getElementById('main'));
      //this.setState({ value: 'https://broadmeadowpark.focus-pluto.co.uk/plot-overview/' + this.props.id });
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.updateCount !== this.props.updateCount) {
    //   if (this.props.apiMessage === 'Property Updated') {
    //     this.props.favourite = this.props.updatedProperty.acf.details.favourite;
    //   }
    // }
  }

  favouriteClick = (_type) => {
    this.props.favouriteClick(this.props.favourite === "true" ? "false" : "true", this.props.id);
    //this.props.handleFavouriteClick(this.props.favourite === "true" ? "false" : "true", this.props.id);
  }

  shareClick = () => {
    this.props.shareClick(this.props.id);
    //this.setState({ isModalOpen: true})
  }

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  // handleShare = () => {
  //   if(this.isValid()) {

  //     this.setState({ showFormSubmitting: true });
  //     let formInput = {};
  //     let input_values = {};

  //     input_values["input_3"] = this.state.yourName;
  //     input_values["input_17"] = this.state.yourEmail;
  //     input_values["input_18"] = this.state.friendName;
  //     input_values["input_16"] = this.state.friendEmail;
  //     input_values["input_15"] = this.props.id;
  

  //     formInput.input_values = input_values;
  
  //     axios
  //       .post(
  //         //"http://newmanplaceapp.local.test/app/gravityformsapi/forms/1/submissions",
  //         "http://localhost/broadwalkwp/gravityformsapi/forms/3/submissions",
  //         formInput
  //       )
  //       .then((response) => {
  //         this.setState({ formSubmitted: true, showFormSubmitting: false, isModalOpen: false, yourName: "", yourEmail: "", friendName: "", friendEmail: "" });
  //       })
  //       .catch((error) => {
  //         this.setState({ formSubmitted: false, showFormSubmitting: false });
  //       });
  
      
  //   }
  // }

  handleCopy = () => {
    
  }

  // isValid = () => {
  //   var _yourNameInvalid = false;
  //   var _yourEmailInvalid = false;
  //   if (this.state.yourName === '') {
  //     _yourNameInvalid = true;
  //     this.setState({ yourNameInvalid: true })
  //   } else {
  //     _yourNameInvalid = false;
  //     this.setState({ yourNameInvalid: false })
  //   }

  //   if (this.state.yourEmail === '') {
  //     _yourEmailInvalid = true;
  //     this.setState({ yourEmailInvalid: true })
  //   } else {
  //     _yourEmailInvalid = false;
  //     this.setState({ yourEmailInvalid: false })
  //   }

  //   if (_yourNameInvalid || _yourEmailInvalid)
  //     return false;

  //   return true;
  // }

  // openPlot = (path) => {
  //   this.props.history.push(path);
  // }

  render () {
    let bathroomLabel = this.props.bathrooms > 1 ? "bathrooms" : "bathroom";
  let bedroomLabel = this.props.bedrooms > 1 ? "bedrooms" : "bedroom";

  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let checkBoxDiv = (
    <div style={{ marginLeft: "15px", paddingBottom: "20px", width: "50%", display: "inline-block", }}>
      <span className="bigcheck-register cancel-drag">
        <label
          className="bigcheck-register"
          style={{ fontSize: "16px", color: "#18243F", fontFamily: "HelveticaNeue, Arial, sans-serif", }}
        >
          {" "}
          Compare home
          <input
            type="checkbox"
            checked={this.props.selected}
            onChange={this.compareCheckedHandler}
            className="bigcheck-register"
            id={`prop-check-${this.props.id}`}
            name="Agreed"
            value="Agreed"
          />
          <span className="bigcheck-target-register"></span>
        </label>
      </span>
    </div>
  );

  if (this.props.maxCompareSelected === true && this.props.selected === false) {
    checkBoxDiv = (
      <div style={{ marginLeft: "15px", paddingBottom: "20px", width: "50%", display: "inline-block", }}>
      <span className="bigcheck-register cancel-drag">
        <label
          className="bigcheck-register"
          style={{ fontSize: "20px", color: "gray", fontFamily: "HelveticaNeue, Arial, sans-serif", }}
        >
          {" "}
          Compare home
          <input
            type="checkbox"
            checked={this.props.selected}
            onChange={this.compareCheckedHandler}
            className="bigcheck-register-disabled"
            name="Agreed"
            value="Agreed"
            id={`prop-check-${this.props.id}`}
            style={{ color: "#ff0000" }}
            disabled={true}
          />
          <span className="bigcheck-target-register"></span>
        </label>
      </span>
    </div>
    );
  }

  return (
    <div id='main' style={{ width: "400px" }}>
      <div style={{ position: "relative", marginBottom: "20px" }}>
        <img
          src={this.props.image}
          style={{ width: "400px", height: "300px", objectFit: "cover" }}
          alt=""
        />
        <div className="statusOverlay">
          {this.props.status}
        </div>
        {this.props.favourite === "true" ? 
        <div onClick={() => this.favouriteClick('true')} className="heartOverlayTrue cancel-drag">
          <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
        </div> :
        <div onClick={() => this.favouriteClick('false')} className="heartOverlayFalse cancel-drag">
          <FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>
        </div>}
        <div onClick={() => this.shareClick()} className="shareIcon cancel-drag">
          <FontAwesomeIcon icon={faShare}></FontAwesomeIcon>
        </div>
        <Link
          to={"/plot-overview/" + this.props.id}
          className="cancel-drag"
          style={{ position: "absolute", left: '0px', top: '60px', width: '100%', height: '78%' }}
        >
        </Link>
      </div>
      <p style={{ color: "#18243F", fontSize: "20px", margin: "20px 0 5px 0", marginBottom: '0px', fontWeight: "bold", }}>
        {this.props.title}
      </p>
      <p style={{ color: "#18243F", fontSize: "20px", margin: this.props.soPriceText !== "" ? "0px" : "0 0 20px 0" }}>
        {this.props.soPrice === "" ? this.props.price === "" ? "POA" : "From £" + numberWithCommas(this.props.price) : "£" + numberWithCommas(this.props.soPrice) }
      </p>
      {this.props.soPriceText !== "" ?
      <p style={{ color: "#18243F", fontSize: "16px", margin: "0 0 20px 0" }}>
        {this.props.soPriceText}
      </p> : null}

      <div
        style={{ color: "#18243F", fontSize: "18px", margin: "10px 0px", fontWeight: "bold", fontFamily: "HelveticaNeue, Arial, sans-serif", }}
        
      >
        <img
          src={require("assets/img/icons/icon_blue_bedroom.png")}
          alt=""
          style={{ width: "25px", height: "auto", marginRight: "10px" }}
        />{" "}
        {this.props.bedrooms} {bedroomLabel}
        <img
          src={require("assets/img/icons/icon_blue_bathroom.png")}
          alt=""
          style={{
            width: "25px",
            height: "auto",
            marginLeft: "30px",
            marginRight: "10px",
          }}
        />{" "}
        {this.props.bathrooms} {bathroomLabel}
      </div>
      <div
        
      >
        <p style={{
          color: "#18243F",
          float: "left",
          fontSize: "16px",
          wordWrap: "normal",
          maxWidth: "100%",
          height: "130px",
          maxHeight: "130px",
          overflow: "auto",
          marginBottom: "30px",
          paddingRight: "20px",
        }}>{this.props.description}</p>
      </div>
      <div>
        <Link
          to={"/plot-overview/" + this.props.id}
          className="viewPropsButtonBrown cancel-drag"
          style={{ color: "white", display: 'inline-block', background: '#37596b', textTransform: 'none', fontWeight: 'bold', fontSize: '19px', padding: '10px' }}
        >
          View home
        </Link>
        {checkBoxDiv}
      </div>
      {/* <Modal
          isOpen={this.state.isModalOpen}
          onAfterOpen={() => this.afterOpenModal()}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={_subtitle => (subtitle = _subtitle)} style={{ marginTop: '0px', marginBottom: '30px', borderBottom: '1px solid lightgray', paddingBottom: '10px', fontSize: '22px', textAlign: 'center' }}>Share Plot</h2>
          <div onClick={() => this.setState({ isModalOpen: false })} className="closeShareModalIcon">
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </div>
            <TextInputField
              label="Your name"
              required
              value={this.state.yourName}
              onChange={e => this.setState({ yourName: e.target.value })}
              isInvalid={this.state.yourNameInvalid}
            />
            <TextInputField
              label="Your email"
              required
              value={this.state.yourEmail}
              onChange={e => this.setState({ yourEmail: e.target.value })}
              isInvalid={this.state.yourEmailInvalid}
            />
            <TextInputField
              label="Friend's name"
              value={this.state.friendName}
              onChange={e => this.setState({ friendName: e.target.value })}
            />
            <TextInputField
              label="Friend's email"
              value={this.state.friendEmail}
              onChange={e => this.setState({ friendEmail: e.target.value })}
            />
            <Pane paddingBottom={20}>
              <Button appearance="primary" iconAfter={ShareIcon} width={85} onClick={this.handleShare} style={{ background: 'rgb(55, 89, 107)', width: '100%', paddingLeft: '40%', marginBottom: '30px', height: '40px'}}>Send</Button>
              <h2 style={{ marginTop: '0px', marginBottom: '10px', paddingTop: '10px', paddingBottom: '10px', fontSize: '22px', textAlign: 'center', borderTop: '1px solid lightgray' }}>Copy link</h2>
              <CopyToClipboard text={this.state.value} onCopy={() => this.setState({copied: true})}>
                <Button appearance="primary" iconAfter={LinkIcon} width={85} onClick={this.handleCopyLink} style={{ background: 'rgb(55, 89, 107)', width: '100%', paddingLeft: '36%', height: '40px'}}>Copy Link</Button>
              </CopyToClipboard>
            </Pane>
            {this.state.showFormSubmitting ?
            <Pane display="flex" alignItems="center" justifyContent="center" height="100%" background="rgba(0,0,0,0.05)" style={{ position: 'absolute', width: '100%', top: '0' }}>
              <Spinner></Spinner>
            </Pane> : null}
        </Modal> */}
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    apiMessage: state.prop.apiMessage,
    updatedProperty : state.prop.updatedProperty,
    updateCount: state.prop.updateCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //handleFavouriteClick: (t,id) => dispatch(actionCreators.handleFavouriteClick(t,id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(property);