import React, { useEffect } from "react";

import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import { connect } from "react-redux";

function Index(props) {
  let pageHeader = React.createRef();

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(false);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === props.sitePages.carouselItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? props.sitePages.carouselItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header page-header--home" data-parallax={true} ref={pageHeader}>
        <div className="filter" />
          <Container style={{ height: "94vh" }} className="branding">

            <div
              className="motto text-right"
              style={{ zIndex: "300",}}
            >
              <h3 className="body-Kannada home-strap" style={{ fontSize: '49px', paddingLeft: '25px'  }}>
                {props.sitePages.strapline}
              </h3>
              <h3 className="body-Kannada home-address" style={{ fontSize: '30px', paddingLeft: '325px'  }}>
                {props.sitePages.address}
              </h3>
            </div>

            {/* <div className="motto text-center motto-strap">
              <img src={props.sitePages.project_logo} alt=""  className="home-logo" />
            </div> */}
            {/* <div className="motto bottom-logo">
              <img src={props.sitePages.bottom_logo} alt=""  className="home-logo" />
            </div> */}
          </Container>
        </div>
      {props.sitePages.background_video && props.sitePages.show_background_video ?
        <video
          autoPlay
          muted
          loop
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            minWidth: "100%",
            minHeight: "100%",
          }}
        >
          <source src={props.sitePages.background_video} type="video/mp4" />
        </video>
      :
      <div style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%' }} id="carousel">
         <Container style={{ margin: '0px', maxWidth: '100%'}}>
          <Row>
            <Col className="page-header page-header--home ml-auto mr-auto" style={{ padding: '0px'}}>
              <Card className="page-carousel" style={{ height: '100%', marginBottom: '0px', backgroundColor: '#7f7f7f'}}>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  style={{ height: '100%' }}
                >
                  {props.sitePages.carouselItems.length > 1 && 
                    <CarouselIndicators
                      items={props.sitePages.carouselItems}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                  }
                  {props.sitePages.carouselItems.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.id}
                      >
                        <img style={{ height: "100%", borderRadius: '0px', objectFit: 'cover' }} src={item.src} alt={item.altText} />
                      </CarouselItem>
                    );
                  })}

                  {props.sitePages.carouselItems.length > 1 && 
                    <a
                      className="left carousel-control carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                      <img src={require("assets/img/icons/arrow-left.png")} alt="" />
                      <span className="sr-only">Previous</span>
                    </a>
                  }

                  {props.sitePages.carouselItems.length > 1 && 
                    <a
                      className="right carousel-control carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                      <img src={require("assets/img/icons/arrow-right.png")} alt="" />
                      <span className="sr-only">Next</span>
                    </a>
                  }
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    sitePages: state.pag.sitePages
  };
};

export default connect(mapStateToProps)(Index);
