import "config.js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import filterSettingsReducer from './store/reducers/filterSettings';
import developmentReducer from './store/reducers/development';
import propertesReducer from './store/reducers/properties';
import devStylesReducer from './store/reducers/devStyles';
import blocksReducer from './store/reducers/apartmentBlocks';
import navigationReducer from './store/reducers/navigation';
import sitePagesReducer from './store/reducers/sitePages';
import aboutCatalystReducer from './store/reducers/aboutCatalyst';
import buyingSchemeReducer from './store/reducers/buyingScheme';
import thunk from 'redux-thunk';

// styles
import "assets/css/bootstrap.min.css";
import "assets/css/paper-kit.css";
import "assets/demo/demo.css";
import App from './App';

const rootReducer = combineReducers({
    ftr: filterSettingsReducer,
    dev: developmentReducer,
    prop: propertesReducer,
    sty: devStylesReducer,
    nav: navigationReducer,
    pag: sitePagesReducer,
    cat: aboutCatalystReducer,
    sch: buyingSchemeReducer,
    blk: blocksReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
            <App />
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
