import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "./store/actions/index";
// pages
import Index from "views/Index.js";
import Location from "views/LocationPage";
import About from "views/AboutPage";
import Timeline from "views/TimelinePage";
import Gallery from "views/GalleryPage";
import Exterior from "views/ExteriorPage";
import Interior from "views/InteriorPage";
import VideoPage from "views/VideoPage";
import LocalArea from "views/LocalAreaPage";
import Register from "views/RegisterPage.js";
import Share from "views/SharePage.js";
import Properties from "views/PropertiesPage.js";
import Terms from "views/TermsPage.js";
import Privacy from "views/PrivacyPage.js";
import PlotOverview from "views/PlotOverviewPage.js";
import Favourites from "views/Favourites.js";
import SiteplanPhase from "views/SiteplanPhasePage.js";
import ApartmentFloors from "views/ApartmentFloorPage";
import AboutCatalyst from "views/AboutCatalyst";
import BuyingScheme from "views/BuyingScheme";

import LiveNavbar from "./components/Navbars/LiveNavBar";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBarItems: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.navigation !== prevProps.navigation) {

      if(this.props.navigation !== undefined) {

        this.setState({ navBarItems: this.props.navigation.sort(compareValues("order", "asc")) });
  
        function compareValues(key, order = "asc") {
          return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
              // property doesn't exist on either object
              return 0;
            }
    
            const varA =
              typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
            const varB =
              typeof b[key] === "string" ? b[key].toUpperCase() : b[key];
    
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return order === "desc" ? comparison * -1 : comparison;
          };
        }
  
      }
   
    }
  
  }

  componentDidMount() {
    this.props.onInitSitePagesSettings();
    this.props.onInitNavigationSettings();
    this.props.onInitDevStylesSettings();
    this.props.onInitFilterSettings();
    this.props.onInitDevelopmentSettings();
    this.props.onInitPropertySettings();
    this.props.onInitApartmentBlocks();
    this.props.onInitAboutCatalystSettings();
    this.props.onInitBuyingSchemeSettings();
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <div>
            <LiveNavbar items={this.state.navBarItems} />
          </div>
          <Switch>
            <Route
              path="/index"
              render={(props) => <Index {...this.props} />}
            />
            <Route
              path="/about-latimer"
              render={(props) => <AboutCatalyst {...this.props} />}
            />
            <Route
              path="/buying-schemes"
              render={(props) => <BuyingScheme {...this.props} />}
            />
            <Route
              path="/location"
              render={(props) => <Location {...this.props} />}
            />
            <Route
              path="/about"
              render={(props) => <About {...this.props} />}
            />
            <Route
              path="/gallery-exterior"
              render={(props) => <Exterior {...this.props} />}
            />
            <Route
              path="/gallery-interior"
              render={(props) => <Interior {...this.props} />}
            />
            <Route
              path="/gallery-video"
              render={(props) => <VideoPage {...this.props} />}
            />
            <Route
              path="/homes-for-sale/:plot"
              render={(props) => <Properties {...Object.assign(props, this.props)} />}
            />
            <Route
              path="/homes-for-sale"
              render={(props) => <Properties {...this.props} />}
            />
            <Route
              path="/favourites"
              render={(props) => <Favourites {...this.props} />}
            />
            <Route
              path="/plot-overview/:plot"
              render={(props) => <PlotOverview {...this.props} />}
            />
            <Route
              path="/share-property/:plot"
              render={(props) => <Share {...props} />}
            />
            <Route
              path="/siteplan"
              render={(props) => <SiteplanPhase {...this.props} />}
            />
            <Route
              path="/apartment-floors/:block"
              render={(props) => <ApartmentFloors {...this.props} />}
            />
            <Redirect to="/index" />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navigation: state.nav.navigation,
    filterError: state.ftr.error,
    devError: state.dev.error,
    propError: state.prop.error,
    devStylesError: state.sty.error,
    navigationError: state.nav.error,
    sitePagesError: state.pag.error,
    aboutCatalystError: state.cat.error,
    buyingSchemeError: state.sch.error,
    apartmentBlocksError: state.blk.error
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onInitFilterSettings: () => dispatch(actionCreators.fetchFilterSettings()),
    onInitDevelopmentSettings: () => dispatch(actionCreators.fetchDevelopment()),
    onInitPropertySettings: () => dispatch(actionCreators.fetchProperties()),
    onInitDevStylesSettings: () => dispatch(actionCreators.fetchDevStyles()),
    onInitNavigationSettings: () => dispatch(actionCreators.fetchNavigation()),
    onInitSitePagesSettings: () => dispatch(actionCreators.fetchSitePages()),
    onInitApartmentBlocks: () => dispatch(actionCreators.fetchBlocks()),
    onInitAboutCatalystSettings: () => dispatch(actionCreators.fetchAboutCatalyst()),
    onInitBuyingSchemeSettings: () => dispatch(actionCreators.fetchBuyingScheme()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
