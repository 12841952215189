import React from "react";
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import { Link } from "react-router-dom";
import ReactCarouselSmall from '../../UI/ReactCarousel/ReactCarouselSmall';
import ReactCarouselSmallFloorplan from '../../UI/ReactCarousel/ReactCarouselSmallFloorplan';

const compareProperty = (props) => {

  let bathroomLabel = props.propData.bathrooms > 1 ? "bathrooms" : "bathroom";
  let bedroomLabel = props.propData.bedrooms > 1 ? "bedrooms" : "bedroom";

  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Aux>
    <div style={{ width: "100%" }}>

      <div style={{ position: "relative", marginBottom: "20px" }}>
        {props.propData.floorplans !== undefined ? 
        <ReactCarouselSmall
                      slides={props.propData.slides}
                    />
                    : null}
        <div className="statusOverlay">
          {props.propData.status}
        </div>

      </div>

      <div style={{width: '50%', position: 'relative', padding: '0px 20px 10px 30px', borderRight: '1px rgba(255,255,255,.2) solid', float: 'left'}}>
        <div style={{height: '35vh', marginBottom: '20px', border: '1px transparent solid'}}>
          <p
            style={{ color: "#37596b", fontSize: "22px", margin: "20px 0 5px 0" }}
            className="bold-GalanoGrotesque"
          >
            {props.propData.title}
          </p>
          <p
            style={{ color: "#37596b", fontSize: "22px", margin: props.propData.soPriceText !== "" ? "0px" : "0 0 20px 0" }}
            className="body-GalanoGrotesque"
          >
            {props.propData.soPrice === "" ? props.propData.price === "" ? "POA" : "From £" + numberWithCommas(props.propData.price) : "£" + numberWithCommas(props.propData.soPrice) }
          </p>
          {props.propData.soPriceText !== "" ?
          <p style={{ color: "#18243F", fontSize: "18px", margin: "0 0 20px 0" }}>
            {props.propData.soPriceText}
          </p> : null}

          <div
            style={{ color: "#37596b", fontSize: "14px", margin: "20px 0 0 0" }}
            className="body-GalanoGrotesque"
          >
            <img
              src={require("assets/img/icons/icon_blue_bedroom.png")}
              alt=""
              style={{ width: "25px", height: "auto", marginRight: "10px" }}
            />{" "}
            {props.propData.bedrooms} {bedroomLabel}
          </div>

          <div
            style={{ color: "#37596b", fontSize: "14px", margin: "10px 0 20px 0" }}
            className="body-GalanoGrotesque"
          >
            <img
              src={require("assets/img/icons/icon_blue_bathroom.png")}
              alt=""
              style={{
                width: "25px",
                height: "auto",
                marginRight: "10px",
              }}
            />{" "}
            {props.propData.bathrooms} {bathroomLabel}
          </div>

          <div className="body-GalanoGrotesque" style={{
            color: "#37596b",
            wordWrap: "normal",
            maxHeight: "170px",
            overflow: "auto",
            fontSize: "14px",
            marginTop: '0px'
          }}>
          {props.propData.description}
          </div>
        </div>
      </div>
      
      <div style={{width: '50%', padding: '0px 20px 10px 30px', position: 'relative', float: 'left'}}>
        <div style={{height: '35vh', border: '1px transparent solid', marginBottom: '20px'}}>
          {props.propData.roomSizes !== false ? 
          props.propData.roomSizes.map(room => {
          return (
            <div style={{width: '100%', padding: '14px 10px 30px 1px', marginTop: '30px', borderBottom: '1px #37596b solid', marginTop: '0px' }}>
              <p  className="bold-GalanoGrotesque" style={{color: '#f2eec4', fontSize: '13px', fontWeight: 'bold', textTransform: 'uppercase'}}>{room.room_name}</p>
              <div  className="body-GalanoGrotesque" style={{color: '#37596b', paddingBottom: '10px', float: 'left', position: 'relative', width: '170px', clear: 'none', fontSize: '13px'}}>{room.room_size_in_meters}</div>
              <div  className="body-GalanoGrotesque" style={{color: '#37596b', marginBottom: '10px', float: 'left', position: 'relative', fontSize: '13px'}}>{room.room_size_in_feet}</div>
            </div>
          )}) : null}
        </div>
      </div>

      <div style={{ clear: 'both' }}></div>  
    
      <div className="compare-home-top" style={{ position: "relative", marginBottom: "20px", marginTop: "20px" }}>
        {props.propData.floorplans !== undefined ? 
        <ReactCarouselSmallFloorplan
                        slides={props.propData.floorplans}
                      />
                      : null}
      </div>

      <div style={{width: '50%', padding: '0px 20px 10px 30px', position: 'relative', margin: '0 auto', textAlign: 'center'}}>
        <Link
          to={"/plot-overview/" + props.propData.id}
          className="bold-GalanoGrotesque viewPropsButtonBrownCompare"
          style={{ color: "white", display: 'inline-block' }}
        >
          VIEW HOME
        </Link>
      </div>

      {/* <div style={{width: '50%', padding: '0px 20px 10px 30px', position: 'relative', float: 'left'}}>
        <Link
          to="/register-interest"
          className="bold-GalanoGrotesque viewPropsButtonBlue"
          style={{ color: "#000", display: 'inline-block' }}
        >
          REGISTER INTEREST
        </Link>
      </div> */}

    </div>
    </Aux>
  );
};

export default compareProperty;

