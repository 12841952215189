import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import ReactCarouselSingleImage from "../components/UI/ReactCarousel/ReactCarouselSingleImage";
import ReactCarouselSingleImagePO from "../components/UI/ReactCarousel/ReactCarouselSingleImagePO";

import ReactCarouselFloorPlan from "../components/UI/ReactCarousel/ReactCarouselFloorPlan";
import ReactCarouselFloorPlanPO from "../components/UI/ReactCarousel/ReactCarouselFloorplanPO";

import ReactCarouselGallery from "../components/UI/ReactCarousel/ReactCarouselGallery";
import { Container, Row, Col } from "reactstrap";
import "./PlotOverviewPage.css";
import { connect } from "react-redux";
import _ from "lodash";
import Aux from '../hoc/Auxiliary/Auxiliary';
import { Link } from "react-router-dom";

class PlotOverviewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      aboutDevelopment: {},
      propertySlides: [],
      floorplanSlides: [],
      items: null,
      activeIndex: 0,
      animating: false,
      properties: null,
      bedroomLabel: "",
      bathroomLabel: "",
      showOverview: true,
      activeTab: 'overview',
      overviewButtonBorder: "2px solid rgb(194, 166, 136)",
      galleryButtonBorder: "none",
      tab1ButtonBorder: "none",
      tab2ButtonBorder: "none",
      tab3ButtonBorder: "none"
    };
  }



  goBack() {
    // this.props.history.goBack();
    //props.history.push("properties");
  }

  overviewSection = null;
  gallerySection = null;
  tab1Section = null;
  tab2Section = null;
  tab3Section = null;
  plotNavSection = null;
  bodySection = null;

  responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
  };

  onchange = (value) => {
    //this.setState({ value });
  };

  getPropertyFromProps(plot) {
    
    let property = _.find(this.props.properties, { id: Number(plot)});

    if(property !== undefined) {

        this.setState({
          properties: property,
          bathroomLabel: Number(property.bathrooms) > 1 ? "bathrooms" : "bathroom",
          bedroomLabel: Number(property.bedrooms) > 1 ? "bedrooms" : "bedroom",
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.properties !== prevProps.properties) {
      this.getPropertyFromProps(this.props.match.params.plot);
    }
  }

  componentDidMount() {
    this.getPropertyFromProps(this.props.match.params.plot);

    document.documentElement.classList.remove("nav-open");
  }

  componentWillMount() {}

  showGalleryClick = () => {
    this.setState({
      showOverview: false,
      activeTab: "gallery",
      galleryButtonBorder: "2px solid rgb(194, 166, 136)",
      overviewButtonBorder: "none",
      tab1ButtonBorder: "none",
      tab2ButtonBorder: "none",
      tab3ButtonBorder: "none"
    });
  };

  showOverviewClick = () => {
    this.setState({
      showOverview: true,
      activeTab: "overview",
      overviewButtonBorder: "2px solid rgb(194, 166, 136)",
      galleryButtonBorder: "none",
      tab1ButtonBorder: "none",
      tab2ButtonBorder: "none",
      tab3ButtonBorder: "none"
    });
  };

  showTab1Click = () => {
    this.setState({
      showOverview: true,
      activeTab: "tab1",
      overviewButtonBorder: "none",
      galleryButtonBorder: "none",
      tab1ButtonBorder: "2px solid rgb(194, 166, 136)",
      tab2ButtonBorder: "none",
      tab3ButtonBorder: "none"
    });
  };

  showTab2Click = () => {
    this.setState({
      showOverview: true,
      activeTab: "tab2",
      overviewButtonBorder: "none",
      galleryButtonBorder: "none",
      tab1ButtonBorder: "none",
      tab2ButtonBorder: "2px solid rgb(194, 166, 136)",
      tab3ButtonBorder: "none"
    });
  };

  showTab3Click = () => {
    this.setState({
      showOverview: true,
      activeTab: "tab3",
      overviewButtonBorder: "none",
      galleryButtonBorder: "none",
      tab1ButtonBorder: "none",
      tab2ButtonBorder: "none",
      tab3ButtonBorder: "2px solid rgb(194, 166, 136)"
    });
  };

  buildPlotNavSection = (property) => {

    let tab1 = null;
    let tab2 = null;
    let tab3 = null;

    if(property.plotTab1 !== null) {

      tab1 = (
        <div style={{display: "inline-block", verticalAlign: "bottom"}}>
          <p
            onClick={this.showTab1Click}
            className={(this.state.activeTab == 'tab1' ? 'active' : '') + ' galleryButton tabButton'}
          >
            {property.plotTab1.title}
          </p>
        </div>
      );
    }

    if(property.plotTab2 !== null) {

      tab2 = (
        <div style={{display: "inline-block", verticalAlign: "bottom"}}>
          <p
            onClick={this.showTab2Click}
            className={(this.state.activeTab == 'tab2' ? 'active' : '') + ' galleryButton tabButton'}
          >
            {property.plotTab2.title}
          </p>
        </div>
      );
    }

    if(property.plotTab3 !== null) {

      tab3 = (
        <div style={{display: "inline-block", verticalAlign: "bottom"}}>
          <p
            onClick={this.showTab3Click}
            className={(this.state.activeTab == 'tab3' ? 'active' : '') + ' galleryButton tabButton'}
          >
            {property.plotTab3.title}
          </p>
        </div>
      );
    }


    this.plotNavSection = (
      <Aux>
        <div style={{display: "inline-block", verticalAlign: "bottom"}}>
          <p
            onClick={this.showOverviewClick}
            className={(this.state.activeTab == 'overview' ? 'active' : '') + ' overViewButton tabButton'}
          >
            OVERVIEW
          </p>
        </div>
        {/* <div style={{display: "inline-block", verticalAlign: "bottom"}}>
          <p
            onClick={this.showGalleryClick}
            className={(this.state.activeTab == 'gallery' ? 'active' : '') + ' galleryButton tabButton'}
          >
            GALLERY
          </p>
        </div> */}
        {tab1}
        {tab2}
        {tab3}
      </Aux>
    );
  }

  buildBodySection = (property) => {

    switch (this.state.activeTab) {
      case "overview":
        this.bodySection = this.buildOverviewSection();
        break;
        case "gallery":
          this.bodySection = this.buildGallerySection();
          break;
        case "tab1":
          this.bodySection = this.buildTabSection(property.plotTab1);
          break;
        case "tab2":
          this.bodySection  = this.buildTabSection(property.plotTab2);
          break;
        case "tab3":
          this.bodySection = this.buildTabSection(property.plotTab3);
          break;
       default: 
         break;
    }
  }

  buildOverviewSection = () => { 

    const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Row style={{ margin: "0px", height: "100%", paddingBottom: "10px" }}>
        <Col sm={12} id="carousel" style={{ padding: "0px" }}>
          <Container
            style={{ margin: "0px", maxWidth: "100%", height: "100%" }}
          >
            <Row style={{ height: "100%" }}>
                {/* <div
                  style={{
                    // height: "60vh",
                    marginBottom: "0px",
                    display: "inline-flex",
                    flexDirection: "inherit",
                    width: "100%"
                  }}
                > */}
                  <Col lg={4} md={12} sm={12} style={{ padding: "0px", }}>
                    <ReactCarouselSingleImagePO slides={this.state.properties.slides} />
                  </Col>
                  <Col lg={8} md={12} sm={12} style={{ padding: "0px 40px", paddingLeft: "0px", marginTop: "-8px" }}>
                    <Row style={{ marginTop: "-20px"}}>
                      <Col sm={6}>
                        <ReactCarouselFloorPlanPO slides={this.state.properties.floorplanSlides} />
                      </Col>
                      <Col sm={6} style={{ paddingTop: "50px", paddingLeft: "50px" }}>
                        <div style={{color: '#0F233F', fontSize: '36px', fontWeight: 'bold'}}>Features</div>
                        <div>
                          {this.state.properties.features !== false ? 
                            this.state.properties.features.map((_feature, index) => {
                            return (
                              
                                <div style={{ color: "#0F233F", fontSize: "13px", paddingRight: "0px", display: "inline-block", fontWeight: "normal", marginRight: "5px", marginTop: '10px'}}>
                                 {_feature.feature_description}
                                </div>
                              
                            )
                          }) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '15px'}}>
                      <Col lg={6} md={12} sm={12} className="plotArrowsOne icons">
                        <img
                          src={require("assets/img/icons/width.png")}
                          alt=""
                          style={{ width: "21px", height: "auto"}}
                        />
                        <img
                          src={require("assets/img/icons/height.png")}
                          alt=""
                          style={{ width: "10px", height: "auto"}}
                        />
                        <img
                          src={require("assets/img/icons/width.png")}
                          alt=""
                          style={{ width: "21px", height: "auto"}}
                        />
                        <img
                          src={require("assets/img/icons/height.png")}
                          alt=""
                          style={{ width: "10px", height: "auto"}}
                        />
                      </Col>
                      <Col className="icons plotArrows" lg={6} md={12} sm={12}>
                        <img
                          src={require("assets/img/icons/width.png")}
                          alt=""
                          style={{ width: "21px", height: "auto"}}
                        />
                        <img
                          src={require("assets/img/icons/height.png")}
                          alt=""
                          style={{ width: "10px", height: "auto"}}
                        />
                        <img
                          src={require("assets/img/icons/width.png")}
                          alt=""
                          style={{ width: "21px", height: "auto"}}
                        />
                        <img
                          src={require("assets/img/icons/height.png")}
                          alt=""
                          style={{ width: "10px", height: "auto"}}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                      <Col lg={6} md={12} sm={12}>
                      <div className="roomSizes" style={{ overflowY: 'scroll', marginLeft: '20px' }}>
                      {this.state.properties.roomSizes !== false ? 
                        this.state.properties.roomSizes.map((room, index) => {
                          if (index % 2 == 0) {
                            return (
                              <Col sm={11} style={{ width: "100%", padding: "0px 10px 0px 0", display: 'inline-block', margin: '5px 15px 0px 15px' }}>
                                
                                <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                  <Col className="roomNameStyle" lg={6} md={6} sm={12}>
                                    {room.room_name}
                                  </Col>
                                  <Col className="roomSizeStyle" lg={6} md={6} sm={12}>
                                    {room.room_size_in_meters} / {room.room_size_in_feet}
                                  </Col>
                                </Row>
                              </Col>
                            )
                          } 
                        }) : null}
                        </div>
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                      <div className="roomSizes" style={{ overflowY: 'scroll', marginLeft: '20px' }}>
                    {this.state.properties.roomSizes !== false ? 
                      this.state.properties.roomSizes.map((room, index) => {
                        if (index % 2 == 1) {
                          return (
                            <Col sm={11} style={{ width: "100%", padding: "0px 10px 0px 0", display: 'inline-block', margin: '5px 15px 0px 15px' }}>
                              
                              <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Col className="roomNameStyle" lg={6} md={6} sm={12}>
                                  {room.room_name}
                                </Col>
                                <Col className="roomSizeStyle" lg={6} md={6} sm={12}>
                                  {room.room_size_in_meters} / {room.room_size_in_feet}
                                </Col>
                              </Row>
                            </Col>
                          )
                        } 
                      }) : null}
                      </div>
                      </Col>
                      </Row>
                   </Col>
                {/* </div> */}
            </Row>
          </Container>
        </Col>
      </Row>
    );

  }

  buildGallerySection = () => { 

    return (
      <ReactCarouselGallery slides={this.state.properties.slides} />
    );
    
  }

  buildTabSection = (tab) => { 

    return (
      <Row style={{ margin: "0px", height: "100%", paddingBottom: "20px" }}>
        <Col sm={12} id="carousel" style={{ padding: "0px" }}>
          <Container
            style={{ margin: "0px", maxWidth: "100%", height: "100%" }}
          >
            <Row style={{ height: "100%" }}>
              <Col className="ml-auto mr-auto" style={{ padding: "0px" }}>
                <div
                  style={{
                    height: "100%",
                    marginBottom: "0px",
                    display: "inline-flex",
                    flexDirection: "inherit",
                  }}
                >
                  <Col
                    sm={5}
                    style={{
                      padding: "0px",
                    }}
                  >
                    <div style={{ marginTop: "100px", padding: "0px 60px" }}>
                      <p
                        className="body-GalanoGrotesque"
                        style={{
                          color: "#52204D",
                          marginBottom: "30px",
                          fontWeight: "400",
                        }}
                      >
                        {tab.text1}
                      </p>
                    </div>

                    <div style={{ marginTop: "100px", padding: "0px 60px" }}>
                      <p
                        className="body-GalanoGrotesque"
                        style={{
                          color: "#52204D",
                          marginBottom: "30px",
                          fontWeight: "400",
                        }}
                      >
                        {tab.text2}
                      </p>
                    </div>

                    <div style={{ marginTop: "100px", padding: "0px 60px" }}>
                      <p
                        className="body-GalanoGrotesque"
                        style={{
                          color: "#52204D",
                          marginBottom: "30px",
                          fontWeight: "400",
                        }}
                      >
                        {tab.text3}
                      </p>
                    </div>
                 
                  </Col>
                  <Col style={{ padding: "0px" }}>
                    <ReactCarouselSingleImage
                      slides={tab.gallery}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
    
  }



  render() {

    if (this.state.properties) {
      this.buildPlotNavSection(this.state.properties);

      this.buildBodySection(this.state.properties);
    
    }





    if (this.state.properties && this.state.showOverview === true) {

      if (this.state.value !== 0) {
        this.setState({ value: 0 });
      }

      this.overviewSection = (
        <Row style={{ margin: "0px", height: "100%", paddingBottom: "20px" }}>
          <Col sm={12} id="carousel" style={{ padding: "0px" }}>
            <Container
              style={{ margin: "0px", maxWidth: "100%", height: "100%" }}
            >
              <Row style={{ height: "100%" }}>
                <Col className="ml-auto mr-auto" style={{ padding: "0px" }}>
                  <div
                    style={{
                      height: "100%",
                      marginBottom: "0px",
                      display: "inline-flex",
                      flexDirection: "inherit",
                    }}
                  >
                    <Col
                      sm={5}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <div style={{ marginTop: "100px", padding: "0px 60px" }}>
                        <p
                          className="body-GalanoGrotesque"
                          style={{
                            color: "#52204D",
                            marginBottom: "30px",
                            fontWeight: "400",
                          }}
                        >
                          {this.state.properties.description}
                        </p>
                      </div>
                      {this.state.properties.roomSizes !== false ? 
                      this.state.properties.roomSizes.map(room => {
                        console.log(room)
                        return (
                          <div
                            style={{
                              width: "60%",
                          padding: "10px 20px 10px 10px",
                          marginLeft: "8%",
                            }}>
                            <p
                              className="bold-GalanoGrotesque"
                              style={{
                                color: "#52204D",
                                fontSize: "18px",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}>{room.room_name}</p>

                              <div
                                className="body-GalanoGrotesque"
                                style={{
                                  color: "#52204D",
                                  paddingBottom: "10px",
                                  float: "left",
                                  position: "relative",
                                  width: "170px",
                                  clear: "none",
                                  fontSize: "16px",
                                }}
                              >
                                {
                                  room.room_size_in_meters
                                }
                              </div>

                              <div
                                className="body-GalanoGrotesque"
                                style={{
                                  color: "#52204D",
                                  marginBottom: "10px",
                                  float: "left",
                                  position: "relative",
                                  fontSize: "16px",
                                }}
                              >
                                {
                                  room.room_size_in_feet
                                }
                              </div>
                          </div>
                        )
                      }) : null}
                    </Col>
                    <Col sm={7} style={{ padding: "0px" }}>
                      <ReactCarouselSingleImage
                        slides={this.state.properties.floorplanSlides}
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      );
      this.gallerySection = null;
    }

   

    if (this.state.properties && this.state.showOverview === false) {
      this.overviewSection = null;

      if (this.state.value !== 0) {
        this.setState({ value: 0 });
      }

      this.gallerySection = (
        <ReactCarouselGallery slides={this.state.properties.slides} />
      );
    }

    return (
      <div
        style={{
          // background: "#f3efc5",
          margin: "0px",
          padding: "0px",
        }}
      >
      <div className="plotHeaderText">
        Plot Overview
      </div>

        <div className="plotHeaderNav">
          <div style={{ background: "#37596b"}}>
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              position: 'absolute',
              top: '10px'
            }}>
              <Link to="/homes-for-sale">
              <button
                className="lowerButtonNarrowTransparentNew"
                onClick={this.goBack}
                style={{ width: "130px", color: '#fff' }}
              >
                <img
                  src={require("assets/img/icons/goldTriangle.png")}
                  alt=""
                  style={{ width: "auto", height: "12px", marginRight: "10px" }}
                />
                <span className="bold-GalanoGrotesque">BACK</span>
              </button>
              </Link>
            </div>
            <span
              className="bold-GalanoGrotesque"
              style={{
                display: 'block',
                verticalAlign: 'middle',
                zIndex: "1",
                margin: "0 auto",
                padding: '10px',
                textAlign: "center",
                color: "#52204D",
                fontWeight: "normal",
                fontSize: "30px",
              }}
            >
              {this.state.properties ? (
                <Fragment>
                  <div
                  style={{ color: "#fff", fontSize: "18px", margin: "20px 0" }}
                  className="body-GalanoGrotesque"
                >
                  <img
                    src={require("assets/img/icons/icon_gold_bedroom.png")}
                    alt=""
                    style={{
                      width: "34px",
                      height: "auto",
                      marginRight: "10px",
                    }}
                  />{" "}
                  {this.state.properties.bedrooms} {this.state.bedroomLabel}
                  <img
                    src={require("assets/img/icons/icon_gold_bathroom.png")}
                    alt=""
                    style={{
                      width: "34px",
                      height: "auto",
                      marginLeft: "30px",
                      marginRight: "10px",
                    }}
                  />{" "}
                  {this.state.properties.bathrooms} {this.state.bathroomLabel}
                </div>
                </Fragment>
              ) : null}
            </span>
          </div>
        </div>

        {this.state.properties ? (
          <Fragment>
            <div className="greyBackgroundPlot"></div>
            <Row
              style={{
                height: "12vh",
                position: "relative",
                marginLeft: "0px", 
                marginRight: "0px"
              }}
            >
              <div
                style={{
                  width: "93%",
                  position: "absolute",
                  // borderBottom: "1px solid #52204d29",
                  bottom: "0px",
                  transform: "translateX(-50%)",
                  left: "50%",
                  textAlign: 'end'
                }}
              >
                {this.state.properties ? (
          <div className="plotOverviewTitle">
            {this.state.properties.subtitle}
            {this.state.properties.title && 
              <div style={{color: '#37596b', fontSize: '30px', fontWeight: 'bold'}}>{this.state.properties.title}</div>
            }
          </div>
        ) : null}
                {this.plotNavSection}
              </div>
            </Row>
            {this.bodySection}
            {/* {this.overviewSection}
            {this.gallerySection}
            {this.tab1Section}
            {this.tab2Section}
            {this.tab3Section} */}
          </Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    properties: state.prop.properties,
  };
};

export default connect(mapStateToProps)(withRouter(PlotOverviewPage));
