import React, { Fragment } from "react";
import ImageMapper from "react-image-mapper";
import PrismaZoom from "react-prismazoom";
import "./SiteplanPage.css";
import PlotFilters from "../components/Property/PropertyFilters/PlotFilters";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class SiteplanPhasePage extends React.Component {
  constructor(props) {
    super(props);

    this._map = React.createRef();
    this.state = {
      data: this.props.branchStaffData,
      hoveredArea: null,
      moveMsg: "",
      imageURL: "../../assets/img/phase1a.png",
      plotMap: {
        name: "my-map",
        areas: [],
      },
      plotsUnfiltered: [],
      scale: 0.1,
      translation: { x: 0, y: 0 },
      controlled: null,
      zoom: 0.1,
      filterHouseType: "all",
      filterAvailability: 'All',
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterMinPrice: 100000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      plotClicked: false,
      selectedPlot: "",
      cursorX: 0,
      cursorY: 0,
      mapReloaded: false,
    };
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onClickOnZoomOut = (event) => {
    this.refs.prismaZoom.zoomOut(0.5);
  };

  onClickOnZoomIn = (event) => {
    this.refs.prismaZoom.zoomIn(0.5);
  };

  onClickCloseInfo = (event) => {
    this.setState({ plotClicked: false, selectedPlot: "" });
  };

  componentDidMount() {
    this.setupMappedProperties();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mapReloaded === true) {
      this.setState({ mapReloaded: false });
    }

    if (this.props.properties !== prevProps.properties || this.props.blocks !== prevProps.blocks) {
      this.setupMappedProperties();
    }

    if (
      prevState.filterHouseType !== this.state.filterHouseType ||
      prevState.filterBedrooms !== this.state.filterBedrooms ||
      prevState.filterPrice !== this.state.filterPrice ||
      prevState.filterMinPrice !== this.state.filterMinPrice ||
      prevState.filterArea !== this.state.filterArea ||
      prevState.filterSharedOwnership !== this.state.filterSharedOwnership ||
      prevState.filterHelpToBuy !== this.state.filterHelpToBuy ||
      prevState.filterAvailability !== this.state.filterAvailability
    ) {
      const house = this.state.filterHouseType,
        beds = this.state.filterBedrooms,
        price = this.state.filterPrice,
        priceMin = this.state.filterMinPrice,
        area = this.state.filterArea,
        ownership = this.state.filterSharedOwnership,
        availability = this.state.filterAvailability,
        help = this.state.filterHelpToBuy;

      let unfiltered = [...this.state.plotsUnfiltered.areas];

      let filtered = unfiltered;
      let filtered1 = unfiltered;

      if (ownership === true && help === true) {
        filtered = [
          ...unfiltered.filter(function (el) {
            return el.sharedOwnership === true && el.helpToBuy === true;
          }),
        ];
      }

      if (ownership === true && help === false) {
        filtered = [
          ...unfiltered.filter(function (el) {
            return el.sharedOwnership === true;
          }),
        ];
      }

      if (ownership === false && help === true) {
        filtered = [
          ...unfiltered.filter(function (el) {
            return el.helpToBuy === true;
          }),
        ];
      }


      if (house !== "all") {
        filtered1 = [
          ...filtered.filter(function (el) {
            return (
              el.houseType === house &&
              el.bedrooms >= Number(beds) &&
              ((el.price <= Number(price) && el.soPrice === "") || (el.soPrice <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
              ((el.price >= Number(priceMin) && el.soPrice === "") || (el.soPrice >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
              el.squareFootage > Number(area)
            );
          }),
        ];
      }

      if (house === "all") {
        if (availability === "All") {
          filtered1 = [
            ...filtered.filter(function (el) {
              return (
                Number(el.bedrooms) >= Number(beds) &&
                ((Number(el.price) <= Number(price) && el.soPrice === "") || (Number(el.soPrice) <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
                ((Number(el.price) >= Number(priceMin) && el.soPrice === "") || (Number(el.soPrice) >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) //&&
                //el.squareFootage > Number(area)
              );
            }),
          ];
        } else {
          filtered1 = [
            ...filtered.filter(function (el) {
              return (
                Number(el.bedrooms) >= Number(beds) &&
                el.status === availability &&
                ((Number(el.price) <= Number(price) && el.soPrice === "") || (Number(el.soPrice) <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
                ((Number(el.price) >= Number(priceMin) && el.soPrice === "") || (Number(el.soPrice) >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) //&&
                //el.squareFootage > Number(area)
              );
            }),
          ];
        }
        
      }

      var _plotmap = {
        name: "my-map",
        areas: filtered1,
      };

      this.setState(
        {
          plotMap: _plotmap,
        },
        this.reDrawCanvas
      );
    }
  }

  setupMappedProperties() {
    const blocks = this.props.blocks;

    let areaItems = this.props.properties.map((x) => {
      let name = x.siteplanCriteria.name;
      let availableApartments = 0;

      if (x.houseType === 'apartment') {
        const apartmentBlock = blocks.find(y => y.id === x.apartmentBlockID);

        if (apartmentBlock) {
          name = apartmentBlock.title;
          availableApartments = apartmentBlock.floors.reduce((obj, v) => {
            obj = (obj || 0) + parseInt(v.available_apartments);
            return obj;
          }, 0);
        }
      }
      return {
        name: name,
        shape: "poly",
        coords: x.siteplanCriteria.coords,
        preFillColor: x.siteplanCriteria.preFillColor,
        fillColor: x.siteplanCriteria.fillColor,
        status: x.siteplanCriteria.status,
        id: x.id,
        apartmentBlockID: x.apartmentBlockID, 
        image: x.image,
        bedrooms: x.bedrooms,
        bathrooms: x.bathrooms,
        description: x.description,
        title: x.title,
        apartments: availableApartments,
        price: x.price,
        soPrice: x.soPrice,
        soPriceText: x.soPriceText,
        roomSizes: x.roomSizes,
        houseType: x.houseType,
        squareFootage: x.squareFootage,
        sharedOwnership: x.sharedOwnership,
        helpToBuy: x.helpToBuy,
      };
    });

    let pMap = {
      name: "my-map",
      areas: areaItems,
    };

    this.setState({ plotMap: pMap, plotsUnfiltered: pMap, mapReloaded: true });
  }

  load() {
    this.setState({ msg: "Interact with image !" });
  }
  clicked(area, evt) {
    this.setState({
      msg:
        "You clicked on " +
        area.shape +
        " at coords " +
        JSON.stringify(area.coords) +
        " !",
      plotClicked: true,
      selectedPlot: area,
      cursorX: evt.clientX,
      cursorY: evt.clientY,
    });
  }
  clickedOutside(evt) {
    var coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      msg:
        "You clicked on the image at coords " + JSON.stringify(coords) + " !",
    });
  }
  enterArea(area) {
    this.setState({
      hoveredArea: area,
      msg:
        "You entered " +
        area.shape +
        " " +
        area.name +
        " at coords " +
        JSON.stringify(area.coords) +
        " !",
    });
  }
  leaveArea(area) {
    this.setState({
      hoveredArea: null,
      msg:
        "You leaved " +
        area.shape +
        " " +
        area.name +
        " at coords " +
        JSON.stringify(area.coords) +
        " !",
    });
  }
  moveOnArea(area, evt) {
    var coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg:
        "You moved on " +
        area.shape +
        " " +
        area.name +
        " at coords " +
        JSON.stringify(coords) +
        " !",
    });
  }

  getTipPosition(area) {
    return { top: area.center[1] + "px", left: area.center[0] + "px" };
  }

  reDrawCanvas() {
    this._map.current.initCanvas();
  }

  selectedHouseTypeChangedHandler = (ret) => {
    this.setState({
      filterHouseType: ret,
    });
  };
  selectedBedroomsChangedHandler = (ret) => {
    this.setState({
      filterBedrooms: ret,
    });
  };
  selectedAvailabilityChangedHandler = (ret) => {
    this.setState({
      filterAvailability: ret,
    });
  };
  selectedPriceChangedHandler = (ret) => {
    this.setState({
      filterPrice: ret,
    });
  };
  selectedMinPriceChangedHandler = (ret) => {
    this.setState({
      filterMinPrice: ret,
    });
  };
  selectedAreaChangedHandler = (ret) => {
    this.setState({
      filterArea: ret,
    });
  };
  sharedOwnershipChangedHandler = (ret) => {
    this.setState({
      filterSharedOwnership: ret,
    });
  };
  helpToBuyChangedHandler = (ret) => {
    this.setState({
      filterHelpToBuy: ret,
    });
  };

  resetControlsHandler = () => {
    let unfiltered = [...this.state.plotsUnfiltered.areas];

    var _plotmap = {
      name: "my-map",
      areas: unfiltered,
    };

    this.setState({
      plotMap: _plotmap,
      filterHouseType: "all",
      filterAvailability: 'All',
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterMinPrice: 100000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };

  render() {
    return (
      <Fragment>
        <Fragment>
          <div className="App-wrapper" style={{backgroundColor: '#c3cdd2', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100%'}}>
            <PrismaZoom onZoomChange={this.onZoomChange} ref="prismaZoom" maxZoom={3}>
              <ImageMapper
                ref={this._map}
                src={require("assets/img/sitemapBroadwalkNew2.png")}
                map={this.state.plotMap}
                width={1920}
                //width={window.innerWidth}
                onLoad={() => this.load()}
                onMouseEnter={(area) => this.enterArea(area)}
                onMouseLeave={(area) => this.leaveArea(area)}
                onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                onClick={(area, _, evt) => this.clicked(area, evt)}
                style={{ marginTop: "130px"}}
              />
            </PrismaZoom>
          </div>
          <div className="zoom-buttons-container">
            <div className="App-indicator">
            <img
                src={require("assets/img/icons/zoom-out-blue.png")}
                className="App-button-image"
                onClick={this.onClickOnZoomOut}
                style={{ width: "60px", height: "60px", marginRight: "20px" }}
                alt=""
              />
              <img
                src={require("assets/img/icons/zoom-in-blue.png")}
                className="App-button-image"
                onClick={this.onClickOnZoomIn}
                style={{ width: "60px", height: "60px", marginRight: "20px" }}
                alt=""
              />
            </div>
          </div>
          <div
            style={{
              width: "250px",
              fontSize: "16px",
              background: "#42454A",
              position: "absolute",
              right: "0",
              top: "28px",
              padding: "30px 0 30px 30px",
              fontFamily: "HelveticaNeue, Arial, sans-serif",
              fontWeight: "bold",
              color: "white",
            }}
          >
            <div style={{ display: "flex" }}>
              <div className="availableSODot"></div> Available - S/O
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <div className="availableDot"></div> Available - Private
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <div className="comingSoonPrivateDot"></div> Coming Soon - Private
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <div className="comingSoonSODot"></div> Coming Soon - S/O
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <div className="reservedDot"></div> Sold/Reserved
            </div>
          </div>
          {/* <div
            style={{
              fontSize: "20px",
              position: "absolute",
              left: "0%",
              top: "17%",
              padding: "30px 0 30px 30px",
              fontFamily: "HelveticaNeue, Arial, sans-serif",
              fontWeight: "bold",
              color: "#42454A",
            }}
          >
            <p>A - Allotment</p>
            <p>SS - Sub Station</p>
            <p>LAP - Local Area of Play</p>
          </div> */}
          {/* <div
            style={{
              fontSize: "14px",
              position: "absolute",
              left: "0",
              top: "-4%",
              padding: "30px 0 30px 30px",
              fontFamily: "HelveticaNeue, Arial, sans-serif",
              fontWeight: "bold",
              color: "#42454A",
            }}
          >
            <h3 style={{ fontWeight: "bold", marginBottom: '10px' }}>Key</h3>
              <div>
                <img
                  src={require("assets/img/icons/keys_green.png")}
                  className="App-button-image"
                  alt=""
                  style={{
                    display: "inline-block",
                    float: "left",
                    width: '25px'
                  }}
                />
                <h5 style={{ display: "inline-block", float: "left", paddingLeft: "1.5rem", fontSize: '18px' }}>Shared Ownership</h5>
              </div>
              <div>
                <img
                  src={require("assets/img/icons/keys_yellow.png")}
                  className="App-button-image"
                  alt=""
                  style={{
                    display: "inline-block",
                    float: "left",
                    width: '25px'
                  }}
                />
                <h5 style={{ display: "inline-block", float: "left", paddingLeft: "1.5rem", fontSize: '18px' }}>Rented Properties</h5>
              </div>
              
          </div> */}
          {/* <div
            style={{
              width: "175px",
              fontSize: "20px",
              position: "absolute",
              right: "5%",
              bottom: "40%",
              padding: "30px 0 30px 30px",
              fontFamily: "HelveticaNeue, Arial, sans-serif",
              fontWeight: "bold",
              color: "white",
            }}
          >
            <img
                src={require("assets/img/icons/sun_compass.png")}
                className="App-button-image"
                alt=""
              />
          </div> */}
          {/* <div
            style={{
              width: "344px",
              fontSize: "18px",
              position: "absolute",
              right: "0%",
              bottom: "25%",
              fontFamily: "HelveticaNeue, Arial, sans-serif",
              fontWeight: "normal",
              color: "#0F233F",
              paddingrIGHT: '20px'
            }}
          >
            <div style={{ display: "flex", fontSize: '26px', fontStyle: 'italic' }}>
            Introducing
            </div>
            <div style={{ display: "flex", fontSize: '20px' }}>
            Broadmeadow Park
            </div>
            <div style={{ display: "flex", marginTop: "15px", fontSize: '14px' }}>
            A collection one, two, three, four & five bedroom homes located in Sandbach, Cheshire. Find the perfect blend of modern living, high quality specification and a traditional laidback way of life.
            </div>
          </div> */}
          {/* <div
            style={{
              width: "490px",
              fontSize: "12px",
              position: "absolute",
              right: "0%",
              bottom: "0%",
              padding: "30px 30px 30px 30px",
              fontFamily: "HelveticaNeue, Arial, sans-serif",
              fontWeight: "normal",
              color: "#0F233F",
            }}
          >
            <div style={{ display: "flex", marginTop: "15px" }}>
              The development layout does not show details of gradients of land, boundary treatments, local authority street lighting or landscaping. It is our intention to build in accordance with this layout. However, there may be occasions when the house designs, boundaries, landscaping and positions of roads and footpaths change as the development proceeds. Please check the details of your chosen property with a Sales Executive prior to reservation.
            </div>
          </div> */}
          <div
            style={{
              position: "fixed",
              bottom: "30px",
              right: "-60px",
              width: "400px",
            }}
          >
            <PlotFilters
              selectedHouseType={this.selectedHouseTypeChangedHandler}
              selectedBedrooms={this.selectedBedroomsChangedHandler}
              selectedPrice={this.selectedPriceChangedHandler}
              selectedMinPrice={this.selectedMinPriceChangedHandler}
              selectedArea={this.selectedAreaChangedHandler}
              sharedOwnershipChecked={this.sharedOwnershipChangedHandler}
              helpToBuyChecked={this.helpToBuyChangedHandler}
              resetControls={this.resetControlsHandler}
              selectedAvailability={this.selectedAvailabilityChangedHandler}
              houseType={this.state.filterHouseType}
              bedrooms={this.state.filterBedrooms}
              price={this.state.filterPrice}
              priceMin={this.state.filterMinPrice}
              area={this.state.filterArea}
              sharedOwnership={this.state.filterSharedOwnership}
              helpToBuy={this.state.filterHelpToBuy}
              availability={this.state.filterAvailability}
              propertyDropdpwnOptions={this.props.propertyDropdpwnOptions}
              priceDropdpwnOptions={this.props.priceDropdpwnOptions}
              priceMinDropdpwnOptions={this.props.priceMinDropdpwnOptions}
              areaDropdpwnOptions={this.props.areaDropdpwnOptions}
              bedroomsDropdpwnOptions={this.props.bedroomsDropdpwnOptions}
              availabilityDropdpwnOptions={this.props.availabilityDropdpwnOptions}
            />
          </div>
          {this.state.plotClicked ? (
            <div
              className="plotInfoWindow"
              style={{
                top: `calc(${this.state.cursorY}px - 90px)`,
                left: this.state.cursorX - 380 < 0 ? '0px' : `calc(${this.state.cursorX}px - 380px)`,
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ background: 'url(' + this.state.selectedPlot.image + ')'}} className="plotInfoWindowImage">
                  {/* <img
                    style={{ width: "265px", maxHeight: "200px", minHeight: "200px" }}
                    src={this.state.selectedPlot.image}
                    alt=""
                  /> */}
                </div>
                <div style={{ width: "100%" }}>
                  <div>
                    <p
                      className="body-GalanoGrotesque"
                      style={{
                        marginTop: "15px",
                        marginLeft: "20px",
                        color: "black",
                      }}
                    >
                      {this.state.selectedPlot.name}
                    </p>
                  </div>
                  {this.state.selectedPlot.houseType === "house" &&
                    <div>
                      {this.state.selectedPlot.soPrice !== "" ?
                      <div
                        style={{
                          fontSize: "14px",
                          margin: "10px 25px 0px",
                          color: "black",
                        }}
                        className="body-GalanoGrotesque"
                      >
                        <p>&pound; {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 6 }).format(this.state.selectedPlot.soPrice)}{" "}</p>
                        <p>{this.state.selectedPlot.soPriceText}</p>
                      </div> : this.state.selectedPlot.soPrice === "" && this.state.selectedPlot.price === "" ?
                      <div
                      style={{
                        fontSize: "14px",
                        margin: "10px 25px 0px",
                        color: "black",
                      }}
                      className="body-GalanoGrotesque"
                    >
                      POA
                    </div> :
                      <div
                        style={{
                          fontSize: "14px",
                          margin: "10px 25px 0px",
                          color: "black",
                        }}
                        className="body-GalanoGrotesque"
                      >
                        &pound; {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 6 }).format(this.state.selectedPlot.price)}{" "}
                      </div>}
                      <div
                        style={{
                          fontSize: "14px",
                          margin: "10px 0px 0px",
                          color: "black",
                        }}
                        className="body-GalanoGrotesque"
                      >
                        <img
                          src={require("assets/img/icons/icon_black_bedroom.svg")}
                          alt=""
                          style={{
                            width: "25px",
                            height: "auto",
                            marginRight: "10px",
                            marginLeft: "20px",
                          }}
                        />{" "}
                        {this.state.selectedPlot.bedrooms}{" "}
                        {this.state.selectedPlot.bedrooms > 1
                          ? "bedrooms"
                          : "bedroom"}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          margin: "10px 0",
                          color: "black",
                        }}
                        className="body-GalanoGrotesque"
                      >
                        <img
                          src={require("assets/img/icons/icon_black_bathroom.svg")}
                          alt=""
                          style={{
                            width: "25px",
                            height: "auto",
                            marginLeft: "20px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        {this.state.selectedPlot.bathrooms}{" "}
                        {this.state.selectedPlot.bathrooms > 1
                          ? "bathrooms"
                          : "bathroom"}
                      </div>
                    </div>
                  }
                  {this.state.selectedPlot.houseType === "apartment" &&
                    <div
                      style={{
                        fontSize: "14px",
                        margin: "10px 25px 0px",
                        color: "black",
                      }}
                      className="body-GalanoGrotesque"
                    >
                      {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(this.state.selectedPlot.apartments)}{" "} Plots Available
                    </div>
                  }
                </div>
              </div>
              <div className="mapPlotOverlay">
                <div style={{ width: "100%" }}>
                  <Link
                    to={
                      this.state.selectedPlot.houseType === "house"
                        ? "/plot-overview/" + this.state.selectedPlot.id
                        : "/apartment-floors/" + this.state.selectedPlot.apartmentBlockID
                    }
                    className="bold-GalanoGrotesque viewPropsButtonSiteplan"
                    style={{ color: "white", fontSize: "12px", display: 'inline-block' }}
                  >
                    FIND OUT MORE
                  </Link>
                </div>
              </div>
              <div className="plotCloseButton" onClick={this.onClickCloseInfo}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15.211" height="14.93" viewBox="0 0 15.211 14.93"><g transform="translate(-688.801 -42.785)"><line x1="13.814" y1="13.5" transform="translate(689.5 43.5)" fill="none" stroke="#3C371A" strokeWidth="2"/><line x1="13.5" y1="13.814" transform="translate(703.314 43.5) rotate(90)" fill="none" stroke="#3C371A" strokeWidth="2"/></g></svg>
              </div>
              <div className="plotInfoWindowBefore"></div>
            </div>
          ) : null}
        </Fragment>
        {/* : null  } */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertyDropdpwnOptions: state.ftr.propertyDropdpwnOptions,
    priceDropdpwnOptions: state.ftr.priceDropdpwnOptions,
    priceMinDropdpwnOptions: state.ftr.priceMinDropdpwnOptions,
    areaDropdpwnOptions: state.ftr.areaDropdpwnOptions,
    bedroomsDropdpwnOptions: state.ftr.bedroomsDropdpwnOptions,
    properties: state.prop.properties,
    blocks: state.blk.apartmentBlocks,
    availabilityDropdpwnOptions: state.ftr.availabilityDropdpwnOptions
  };
};

export default connect(mapStateToProps)(SiteplanPhasePage);
