import React, { Fragment, Component } from "react";

class FormBuilder extends Component {
  formJsx = null;

  componentDidMount() {
    this.formJsx = this.props.fields.map((p, index) => {
      return this.getFieldType(p, index);
    });
  }

  getFieldType = (field, index) => {
    switch (field.type) {
      case "select":
        return this.createSelectInput(field, index);
      case "text":
        return this.createTextInput(field, index);
      // case "hidden":
      //   return this.createHiddenInput(field, index);
      case "email":
        return this.createEmailInput(field, index);
      case "phone":
        return this.createPhoneInput(field, index);
      case "checkbox":
        return this.createCheckboxInput(field, index);
      default:
        break;
    }
  };

  inputChangedHandler = (event, id, type) => {
    let inputName = "input_" + id;
    this.props.inputChanged(event, inputName, type);
  };

  checkboxChangedHandler = (event, id, type) => {
    let inputName = "input_" + id + "_1";
    this.props.inputChanged(event, inputName, type);
  };

  createSelectInput = (field, index) => {
    let body = (
      <div key={index} className={"form-group " + field.cssClass}>
        <select
          onChange={(event) =>
            this.inputChangedHandler(event, field.id, "select")
          }
          id={field.id}
          name={field.id}
          className="form-control"
        >
          {" "}
          {field.choices.map((p, index) => (
            <option key={index} value={p.value}>
              {p.text}
            </option>
          ))}
        </select>
      </div>
    );

    return body;
  };

  // createHiddenInput = (field, index) => {
  //   let body = (
  //     <div key={index}>
        // <input
        //   type="hidden"
        //   id={field.id}
        //   name={field.id}
        // />
  //     </div>
  //   );

  //   return body;
  // };

  createTextInput = (field, index) => {
    let body = (
      <div key={index} className={"form-group " + field.cssClass}>
        <input
          type="text"
          className="form-control"
          id={field.id}
          name={field.id}
          placeholder={field.placeholder}
          onChange={(event) =>
            this.inputChangedHandler(event, field.id, "text")
          }
        />
      </div>
    );

    return body;
  };

  createEmailInput = (field, index) => {
    let body = (
      <div key={index} className={"form-group " + field.cssClass}>
        <input
          type="email"
          className="form-control"
          id={field.id}
          name={field.id}
          placeholder={field.placeholder}
          onChange={(event) =>
            this.inputChangedHandler(event, field.id, "email")
          }
        />
      </div>
    );

    return body;
  };

  createPhoneInput = (field, index) => {
    let body = (
      <div key={index} className={"form-group " + field.cssClass}>
        <input
          type="tel"
          className="form-control"
          id={field.id}
          name={field.id}
          placeholder={field.placeholder}
          onChange={(event) =>
            this.inputChangedHandler(event, field.id, "phone")
          }
        />
      </div>
    );

    return body;
  };

  createCheckboxInput = (field, index) => {
    let body = (
      <div key={index} className={"form-group col-md-12 " + field.cssClass}>
        {field.description !== "" ? (
          <p className="formSectionHeader">{field.description}</p>
        ) : null}

        {field.choices.map((p, index) => (
          <span key={index} className="customcheck-register">
            <label
              className="customcheck-register body-GalanoGrotesque"
              style={{
                fontSize: "15px",
                color: "white",
                marginRight: "20px",
              }}
            >
              {p.text}
              <input
                type="checkbox"
                onChange={(event) =>
                  this.checkboxChangedHandler(event, field.id, "checkbox")
                }
                className="customcheck-register"
                name={field.id}
                value={p.value}
              />
              <span className="customcheck-target-register"></span>
            </label>
          </span>
        ))}
      </div>
    );

    return body;
  };

  render() {
    return <Fragment>{this.formJsx}</Fragment>;
  }
}

export default FormBuilder;
