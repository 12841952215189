import React from "react";

const sixImageSection = (props) => (
  <div className="row" style={{ padding: "0px", margin: "0px", width: "100%" }}>
  <div
    className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
    style={{ padding: "0px" }}
  >
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[0].id, 0);
        }}
        src={props.images[0].src}
        style={{ width: "100%", height: "55vh", objectFit: "cover", objectPosition: "50% 90%", }}
        alt=""
        className="carouselImages"
      />
    </div>
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[1].id, 1);
        }}
        src={props.images[1].src}
        style={{ width: "100%", height: "35vh", objectFit: "cover", objectPosition: "bottom", }}
        alt=""
        className="carouselImages"
      />
    </div>
  </div>
  <div
    className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
    style={{ padding: "0px" }}
  >
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[2].id, 2);
        }}
        src={props.images[2].src}
        style={{ width: "100%", height: "45vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[3].id, 3);
        }}
        src={props.images[3].src}
        style={{ width: "100%", height: "42vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
  </div>
  <div
    className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
    style={{ padding: "0px" }}
  >
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[4].id, 4);
        }}
        src={props.images[4].src}
        style={{ width: "100%", height: "35vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[5].id, 5);
        }}
        src={props.images[5].src}
        style={{ width: "100%", height: "52vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
  </div>
</div>
  );
  
  export default sixImageSection;
