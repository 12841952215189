import React, { Component } from "react";
import {
  Map,
  GoogleApiWrapper,
  InfoWindow,
} from "google-maps-react";
import googleMapStyles from "./mapStyles";
import MapControls from "./MapControls/MapControls";
import CustomMarker from "./CustomMarker";
import { connect } from "react-redux";

export class LocationMap extends Component {  
  mapOptionsCreator(map) {
    return {
      scrollwheel: false,
      zoomControlOptions: {
        position: map.ControlPosition.RIGHT_CENTER, // as long as this is not set it works
        style: map.ZoomControlStyle.SMALL,
      },
      draggable: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      panControl: false,
    };
  }

  state = {
    showTransport: true,
    showFood: true,
    showEducation: true,
    showFitness: true,
    showEntertainment: true,
    showShops: true,
    transportPlaces: [],
    foodPlaces: [],
    educationPlaces: [],
    fitnessPlaces: [],
    entertainmentPlaces: [],
    shopsPlaces: [],
    selectedPlace: "",
    activeMarker: null,
    showingInfoWindow: false,
    imageIcons: [],
    markersSet: false,
    directions: null
  };

  transportMarkers = null;
  foodMarkers = null;
  educationMarkers = null;
  fitnessMarkers = null;
  entertainmentMarkers = null;
  shopsMarkers = null;
  mapControls = null;
  locationMarker = null;

  imgUrl = window.imageUrl;

  get locationCoordinates() {
    return { lat: this.props.aboutDevelopment.latitude , lng: this.props.aboutDevelopment.longitude };
  }

  componentDidMount() {
    // this.setState({ updated: true });

    // this.setState({ imageIcons: this.props.imageIcons });

    // axios
    //   .get("/wp-json/wp/v2/development_styles/209?_embed")
    //   .then((response) => {
    //     this.setState({ imageIcons: response.data.acf.location_images });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }


  setMarkersrendered = () => {
    if (this.state.markersSet === false) {
      this.setState({ markersSet: true });
    }
  };

  transportChangedHandler = (checkStatus) => {
    this.setState({ showTransport: checkStatus });
  };

  foodChangedHandler = (checkStatus) => {
    this.setState({ showFood: checkStatus });
  };

  educationChangedHandler = (checkStatus) => {
    this.setState({ showEducation: checkStatus });
  };

  fitnessChangedHandler = (checkStatus) => {
    this.setState({ showFitness: checkStatus });
  };

  entertainmentChangedHandler = (entertainmentStatus) => {
    this.setState({ showEntertainment: entertainmentStatus });
  };

  shopsChangedHandler = (checkStatus) => {
    this.setState({ showShops: checkStatus });
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      markersSet: true,
    });
  };

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        markersSet: true,
      });
    }
  };

  onInfoWindowClose = () => {};

  resetControlsHandler = () => {
    this.setState({
      showTransport: true,
      showFood: true,
      showEducation: true,
      showFitness: true,
      showEntertainment: true,
      showShops: true,
    });
  };

  getPlaces = (mapProps, map, types, locationName, markerSection) => {
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    let nav = [];

    const request = {
      location: locationName,
      radius: "2000",
      types: [types],
    };

    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        nav = results.map((p) => {
          return {
            id: p.id,
            lat: p.geometry.location.lat(),
            lng: p.geometry.location.lng(),
            name: p.name,
            vicinity: p.vicinity,
            coordinates: {
              lat: p.geometry.location.lat(),
              lng: p.geometry.location.lng(),
            },
          };
        });

        switch (markerSection) {
          case 1:
            this.setState({ transportPlaces: nav });
            break;
          case 2:
            this.setState({ foodPlaces: nav });
            break;
          case 3:
            this.setState({ educationPlaces: nav });
            break;
          case 4:
            this.setState({ fitnessPlaces: nav });
            break;
          case 5:
            this.setState({ entertainmentPlaces: nav });
            break;
          case 6:
            this.setState({ shopsPlaces: nav });
            break;
          default:
            break;
        }
      }
    });
  };

  fetchPlaces = (mapProps, map) => {
    const { google } = mapProps;
    var pyrmont = new google.maps.LatLng(this.props.aboutDevelopment.latitude, this.props.aboutDevelopment.longitude);
    this.getPlaces(mapProps, map, "bus_station", pyrmont, 1);
    this.getPlaces(mapProps, map, "restaurant", pyrmont, 2);
    this.getPlaces(mapProps, map, "school", pyrmont, 3);
    this.getPlaces(mapProps, map, "gym", pyrmont, 4);
    this.getPlaces(mapProps, map, "bar", pyrmont, 5);
    this.getPlaces(mapProps, map, "supermarket", pyrmont, 6);

    // const directionsService = new google.maps.DirectionsService();

    // const origin = { lat: 40.756795, lng: -73.954298 };
    // const destination = { lat: 41.756795, lng: -78.954298 };

    // directionsService.route(
    //   {
    //     origin: origin,
    //     destination: destination,
    //     travelMode: google.maps.TravelMode.DRIVING
    //   },
    //   (result, status) => {
    //     if (status === google.maps.DirectionsStatus.OK) {
    //       this.setState({
    //         directions: result
    //       });
    //     } else {
    //       console.error(`error fetching directions ${result}`);
    //     }
    //   }
    // );
  };

  rad = (x) => {
    return (x * Math.PI) / 180;
  };

  findDistanceFromLocation = (p1, p2) => {
    let R = 6378137; // Earth’s mean radius in meter
    let dLat = this.rad(p2.lat - p1.lat);
    let dLong = this.rad(p2.lng - p1.lng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) *
        Math.cos(this.rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return Math.round(d * 0.00062137 * 10) / 10;
  };

  render() {
    const zoom = 17;
    
    const image = this.imgUrl + this.props.imageIcons.development_marker;

    const transportMarkerImage = this.imgUrl + this.props.imageIcons.transport_marker;
    const educationMarkerImage = this.imgUrl + this.props.imageIcons.education_marker;
    const foodMarkerImage = this.imgUrl + this.props.imageIcons.eating_out_marker;
    const entertainmentMarkerImage = this.imgUrl + this.props.imageIcons.entertainment_marker;
    const fitnessMarkerImage = this.imgUrl + this.props.imageIcons.fitness_marker;
    const shoppingMarkerImage = this.imgUrl + this.props.imageIcons.shopping_marker;

    if (this.state.showTransport === true) {
      this.transportMarkers = this.state.transportPlaces.map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={transportMarkerImage}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            tracksViewChanges={false}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.transportMarkers = null;
    }

    if (this.state.showFood === true) {
      this.foodMarkers = this.state.foodPlaces.map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={foodMarkerImage}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.foodMarkers = null;
    }

    if (this.state.showEducation === true) {
      this.educationMarkers = this.state.educationPlaces.map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={educationMarkerImage}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.educationMarkers = null;
    }

    if (this.state.showFitness === true) {
      this.fitnessMarkers = this.state.fitnessPlaces.map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={fitnessMarkerImage}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.fitnessMarkers = null;
    }

    if (this.state.showEntertainment === true) {
      this.entertainmentMarkers = this.state.entertainmentPlaces.map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={entertainmentMarkerImage}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.entertainmentMarkers = null;
    }

    if (this.state.showShops === true) {
      this.shopsMarkers = this.state.shopsPlaces.map((p, index) => {
        return (
          <CustomMarker
            key={index}
            position={{ lat: p.lat, lng: p.lng }}
            icon={shoppingMarkerImage}
            onClick={this.onMarkerClick}
            name={p.name}
            vicinity={p.vicinity}
            coordinates={p.coordinates}
            updated={this.state.markersSet}
          />
        );
      });
    } else {
      this.shopsMarkers = null;
    }

    if (this.props.imageIcons.development_marker !== null) {
      this.mapControls = (
        <MapControls
          showTransport={this.transportChangedHandler}
          showFood={this.foodChangedHandler}
          showEducation={this.educationChangedHandler}
          showFitness={this.fitnessChangedHandler}
          showEntertainment={this.entertainmentChangedHandler}
          showShops={this.shopsChangedHandler}
          resetControls={this.resetControlsHandler}
          imageIcons={this.props.imageIcons}
          mounted={this.setMarkersrendered}
        />
      );
    }

    this.locationMarker = (
      <CustomMarker
        position={{ lat: this.props.aboutDevelopment.latitude, lng: this.props.aboutDevelopment.longitude }}
        icon={image}
        // onClick={this.onMarkerClick}
        name={"The Lock"}
        updated={this.state.markersSet}
        //this.setMarkersrendered
      />
    );

    return (
      this.props.aboutDevelopment.latitude !== undefined ?
      <div style={{ height: "calc(100vh - 130px)", marginTop: "130px" }}>
        <Map
          google={this.props.google}
          onReady={this.fetchPlaces}
          zoom={zoom}
          initialCenter={{ lat: this.props.aboutDevelopment.latitude, lng: this.props.aboutDevelopment.longitude }}
          disableDefaultUI={false}
          styles={googleMapStyles.theLanes}
          onClick={this.onMapClicked}
          mapTypeControlOptions={{
            position: this.props.google.maps.ControlPosition.TOP_RIGHT,
          }}
        >
          {this.locationMarker}
          {this.transportMarkers}
          {this.foodMarkers}
          {this.educationMarkers}
          {this.fitnessMarkers}
          {this.entertainmentMarkers}
          {this.shopsMarkers}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <div style={{ padding: "10px", display: 'flex' }}>
              {this.state.activeMarker !== null ? 
                <img style={{ marginRight: "20px", height: '50px', width: '50px', marginTop: '8px' }} src={this.state.activeMarker && this.state.activeMarker.icon || null}></img>
                : null}
                <table cellPadding="0" cellSpacing="0">
                  <tr>
                    <td>
                      <p
                        className="body-GalanoGrotesque"
                        style={{ marginTop: "5px" }}
                      >
                        {this.state.selectedPlace.name}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p
                        className="body-GalanoGrotesque"
                        style={{ fontSize: "12px", marginBottom: "0px" }}
                      >
                        {this.state.selectedPlace.vicinity}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p
                        className="body-GalanoGrotesque"
                        style={{ fontSize: "12px" }}
                      >
                        {this.state.selectedPlace.coordinates !== undefined
                          ? this.findDistanceFromLocation(
                              this.locationCoordinates,
                              this.state.selectedPlace.coordinates
                            ) + " miles from development"
                          : ""}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="mapPinkOverlay"></div>
            </div>
          </InfoWindow>
          {/* <DirectionsRenderer
            directions={this.state.directions}
          /> */}
        </Map>
        {this.mapControls}
      </div> : null
    );
  }
}

LocationMap.defaultProps = googleMapStyles;

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyBAiniBmzicSy9pnlPiaU3s3gFI-wo8sMY",
// })(LocationMap);

const mapStateToProps = (state) => {
  return {
    imageIcons: state.sty.imageIcons,
    aboutDevelopment: state.dev.aboutDevelopment
  };
};

export default connect(mapStateToProps)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBAiniBmzicSy9pnlPiaU3s3gFI-wo8sMY",
  })(LocationMap)
);

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCnmWH1M-h-cPbeSbmWxiB0zwtFSNCACeY",
// })(LocationMap);
