import React, { Fragment } from 'react';
import HorizontalTimeline from "react-horizontal-timeline";
import SwipeableViews from 'react-swipeable-views';
import {connect} from 'react-redux';

class TimelinePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.branchStaffData, value: 0,
      previous: 0,
      minEventPadding: 20,
      maxEventPadding: 120,
      linePadding: 100,
      labelWidth: 100,
      fillingMotionStiffness: 150,
      fillingMotionDamping: 25,
      slidingMotionStiffness: 150,
      slidingMotionDamping: 25,
      stylesBackground: '#F8F8F8',
      stylesForeground: '#96C1D7',
      stylesOutline: 'white',
      isTouchEnabled: true,
      isKeyboardEnabled: true,
      isOpenEnding: false,
      isOpenBeginning: false
     };
  }
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }
  componentWillMount() {

  }

  onLeftClick = () => {

  }

  render() {

    const views = this.props.developmentTimeline.map((entry, index) => {
      return (
        <div key={index}>
          <img alt="" src={window.imageUrl + entry.image} style={{ height: '100vh', width: '100%', filter: 'brightness(80%)' }}></img>
          <div style={{ width: '100%', position: 'absolute', top: '50%', translate: 'transformY(-50%)', color: 'white', fontSize: '20px' }}>
            <p style={{ fontSize: '20px', width: '60%', margin: '0 auto', fontWeight: '400' }}>{entry.banner_text}</p>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        {this.props.developmentTimeline.length > 0 ?
        <div>
          <div
            className="motto text-center"
            style={{ zIndex: "300", position: "absolute", left: '45%', top: '30px' }}
          >
            <img src={this.props.sitePages.project_logo} alt="" />
            <br />
          </div>
          <div className="text-center">
            <SwipeableViews
              index={this.state.value}
              onChangeIndex={(index, previous) => {
                this.setState({ value: index, previous: this.state.value });
              }}
              resistance>
              {views}
            </SwipeableViews>
            <img hidden={this.state.value === 0 ? true : false} style={{width: '30px', height: 'auto', position: 'absolute', left: '22px', top: '50%', borderRadius: '12px 12px 0 0', cursor: 'pointer' }} src={require("assets/img/icons/arrow-left.png")} alt="" onClick={() => {
                var newIndex = 0;
                newIndex = this.state.value - 1;
                if (newIndex === -1) {
                  newIndex = this.props.developmentTimeline.length;
                } else {
                  newIndex = this.state.value - 1
                }
                const curIdx = this.state.value;
                this.setState({ value: newIndex, previous: curIdx });
              }}
           />
           <img style={{width: '30px', height: 'auto', position: 'absolute', right: '22px', top: '50%', borderRadius: '12px 12px 0 0', cursor: 'pointer' }} src={require("assets/img/icons/arrow-right.png")} alt="" onClick={() => {

                var newIndex = 0;
                newIndex = this.state.value + 1;
                if (this.props.developmentTimeline.length - 1 < newIndex) {
                  newIndex = 0;
                } else {
                  newIndex = this.state.value + 1;
                }

                const curIdx = this.state.value;
                this.setState({ value: newIndex, previous: curIdx });
              }}
           />
          </div>
          <div className="body-GalanoGrotesque" style={{ width: '100%', height: '100px', position: 'absolute', bottom: '30px'}}>
            <HorizontalTimeline id="timeline"
              fillingMotion={{ stiffness: this.state.fillingMotionStiffness, damping: this.state.fillingMotionDamping }}
              isKeyboardEnabled={this.state.isKeyboardEnabled}
              isTouchEnabled={this.state.isTouchEnabled}
              labelWidth={this.state.labelWidth}
              linePadding={this.state.linePadding}
              maxEventPadding={this.state.maxEventPadding}
              minEventPadding={this.state.minEventPadding}
              slidingMotion={{ stiffness: this.state.slidingMotionStiffness, damping: this.state.slidingMotionDamping }}
              styles={{
                background: this.state.stylesBackground,
                foreground: this.state.stylesForeground,
                outline: this.state.stylesOutline
              }}
              isOpenEnding={this.state.isOpenEnding}
              isOpenBeginning={this.state.isOpenBeginning}
              index={this.state.value}
              indexClick={index => {
                const curIdx = this.state.value;
                this.setState({ value: index, previous: curIdx });
              }} 
              getLabel={(e,t) => {
                return this.props.developmentTimeline[t].label;
              }}
              values={this.props.developmentTimeline.map(x => x.date)} 
              />
          </div>
        </div>
        : null}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    developmentTimeline: state.dev.developmentTimeline,
    sitePages: state.pag.sitePages
  };
}

export default connect(mapStateToProps)(TimelinePage);
