import React from "react";
import Aux from "../../hoc/Auxiliary/Auxiliary";
import ImageMapper from "react-image-mapper";
import PrismaZoom from "react-prismazoom";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import PlotFilters from "../../components/Property/PropertyFilters/PlotFilters";
import "./ApartmentFloorDetails.css";
//import { withRouter } from "react-router";

class ApartmentFloorDetail extends React.Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this.state = {
      selectedPlot: null,
      bathroomLabel: null,
      bedroomLabel: null,
      zoom: 1,
      plotMap: {
        name: "my-map",
        areas: [],
      },
      mapLoaded: false,
      floorplanStyle: 'normalZoom',
      floorplanZoomWidth: 'normalZoomWidth',
      filterHouseType: "all",
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      plotsUnfiltered: [],
      filterreset: false,
      plotClicked: false,
      cursorX: 0,
      cursorY: 0
    };
  }

  mapSection = null;

  componentDidMount() {
    let items = [];
    let itemsProcessed = 0;
    
    this.props.floor.plots.forEach((element) => {
      if (element.coords !== null) {
        items.push(element);
      }

      itemsProcessed++;
      if (itemsProcessed === this.props.floor.plots.length) {
        this.setState({
          mapLoaded: true,
          plotMap: {
            name: "my-map",
            areas: items,
          },
          plotsUnfiltered: {
            name: "my-map",
            areas: items,
          }
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mapLoaded === false && this.state.mapLoaded === true) {
      this._map.current.initCanvas();
    }
    if (this.state.filterreset === true) {
      this.setState({ filterreset: false });
    } else {
      if (
        prevState.filterHouseType !== this.state.filterHouseType ||
        prevState.filterBedrooms !== this.state.filterBedrooms ||
        prevState.filterPrice !== this.state.filterPrice ||
        prevState.filterArea !== this.state.filterArea ||
        prevState.filterSharedOwnership !== this.state.filterSharedOwnership ||
        prevState.filterHelpToBuy !== this.state.filterHelpToBuy
      ) {
        const house = this.state.filterHouseType,
          beds = this.state.filterBedrooms,
          price = this.state.filterPrice,
          area = this.state.filterArea,
          ownership = this.state.filterSharedOwnership,
          help = this.state.filterHelpToBuy;

        let unfiltered = [...this.state.plotsUnfiltered.areas];

        let filtered = unfiltered;
        let filtered1 = unfiltered;

        if (ownership === true && help === true) {
          filtered = [
            ...unfiltered.filter(function (el) {
              return el.sharedOwnership === true && el.helpToBuy === true;
            }),
          ];
        }

        if (ownership === true && help === false) {
          filtered = [
            ...unfiltered.filter(function (el) {
              return el.sharedOwnership === true;
            }),
          ];
        }

        if (ownership === false && help === true) {
          filtered = [
            ...unfiltered.filter(function (el) {
              return el.helpToBuy === true;
            }),
          ];
        }

        if (house !== "all") {
          filtered1 = [
            ...filtered.filter(function (el) {
              return (
                el.houseType === house &&
                el.bedrooms >= Number(beds) &&
                ((el.price <= Number(price) && el.soPrice === "") || (el.soPrice <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
                el.squareFootage > Number(area)
              );
            }),
          ];
        }

        if (house === "all") {

          filtered1 = [
            ...filtered.filter(function (el) {
              return (
                el.bedrooms >= Number(beds) &&
                ((el.price <= Number(price) && el.soPrice === "") || (el.soPrice <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
                el.squareFootage > Number(area)
              );
            }),
          ];
        }

        var _plotmap = {
          name: "my-map",
          areas: filtered1
        }

        this.setState({
          plotMap: _plotmap,
          filterreset: true
        });
      }
    }
  }

  onZoomChange = (zoom) => {
    if (zoom > 1) {
      this.setState({ zoom: zoom, floorplanStyle: 'maxZoom', floorplanZoomWidth: 'maxZoomWidth' });
    }
    if (zoom === 1) {
      this.setState({ zoom: zoom, floorplanStyle: 'normalZoom', floorplanZoomWidth: 'normalZoomWidth' });
    }
    
  };

  onClickOnZoomOut = (event) => {
    this.refs.prismaZoom.zoomOut(0.5);
  };

  onClickOnZoomIn = (event) => {
    this.refs.prismaZoom.zoomIn(0.5);
  };

  clicked(area, evt) {
    this.setState({
      msg:
        "You clicked on " +
        area.shape +
        " at coords " +
        JSON.stringify(area.coords) +
        " !",
        cursorX: evt.clientX,
        cursorY: evt.clientY,
    });

    this.getSelectedPlot(area);
  }

  moveOnImage(evt) {
  }
  moveOnArea(area, evt) {
  }

  getSelectedPlot = (property) => {
    this.refs.prismaZoom.reset();
    this.setState({
      selectedPlot: property,
      bathroomLabel: property.bathrooms > 1 ? "bathrooms" : "bathroom",
      bedroomLabel: property.bedrooms > 1 ? "bedrooms" : "bedroom",
      floorplanStyle: 'normalZoom', 
      floorplanZoomWidth: 'normalZoomWidth',
      zoom: 1,
      plotClicked: true
    });
  };

  selectedHouseTypeChangedHandler = (ret) => {
    this.setState({
      filterHouseType: ret
    });
  };
  selectedBedroomsChangedHandler = (ret) => {
    this.setState({
      filterBedrooms: ret
    });
  };
  selectedPriceChangedHandler = (ret) => {
    this.setState({
      filterPrice: ret
    });
  };
  selectedAreaChangedHandler = (ret) => {
    this.setState({
      filterArea: ret
    });
  };
  sharedOwnershipChangedHandler = (ret) => {
    this.setState({
      filterSharedOwnership: ret
    });
  };
  helpToBuyChangedHandler = (ret) => {
    this.setState({
      filterHelpToBuy: ret
    });
  };

  onClickCloseInfo = (event) => {
    this.setState({ plotClicked: false, selectedPlot: "" });
  };

  resetControlsHandler = () => {
    let unfiltered = [...this.state.plotsUnfiltered.areas];

    var _plotmap = {
      name: "my-map",
      areas: unfiltered
    }

    this.setState({
      plotMap: _plotmap,
      filterreset: true,
      filterHouseType: "all",
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };

  plotSection = null;

  render() {
    if (this.state.mapLoaded) {
      this.mapSection = (
        <Aux>
          <div className={this.state.floorplanStyle}>
            <PrismaZoom
              maxZoom={2}
              onZoomChange={this.onZoomChange}
              ref="prismaZoom"
              zoom={this.state.zoom}
              leftBoundary={310}
              topBoundary={210}
            >
              <ImageMapper
                ref={this._map}
                src={this.props.floor.bigImageUrl}
                map={this.state.plotMap}
                onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                onImageMouseMove={(evt) => this.moveOnImage(evt)}
                onClick={(area, _, evt) => this.clicked(area, evt)}
              />
            </PrismaZoom>
          </div>
          <div className="zoom-buttons-container-floorplan">
            <div className="App-indicator">
              <img src={require("assets/img/icons/zoom-in3.png")} className="App-button-image" onClick={this.onClickOnZoomIn} style={{width: '50px', height: '50px', marginRight: '20px' }} alt="" />
              <img src={require("assets/img/icons/zoom-out3.png")} className="App-button-image" onClick={this.onClickOnZoomOut} style={{width: '50px', height: '50px'}} alt="" />
            </div>
          </div>
        </Aux>
      );
    }

    return (
      <Aux>
        <div className={this.state.floorplanZoomWidth}>
          <p
            className="bold-GalanoGrotesque"
            style={{
              color: "#18243F",
              fontWeight: "bold",
              fontSize: "25px",
              marginTop: "85px",
            }}
          >
            Apartment Block A
          </p>
          <p
            className="bold-GalanoGrotesque"
            style={{
              color: "#18243F",
              fontWeight: "bold",
              fontSize: "18px",
              textTransform: "uppercase",
            }}
          >
            {this.props.floor.floorName}
          </p>
          <div>
            <div
              style={{
                float: "left",
                clear: "none",
                padding: "30px 30px 0 20px",
              }}
            >
              <table cellPadding="5">
                <tbody>
                  <tr>
                    <td>
                      <div className="grey-dot"></div>
                    </td>
                    <td>
                      <span className="dot-text">Coming Soon</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="blue-dot"></div>
                    </td>
                    <td>
                      <span className="dot-text">Available</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="empty-dot"></div>
                    </td>
                    <td>
                      <span className="dot-text">Reserved</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="purple-dot"></div>
                    </td>
                    <td>
                      <span className="dot-text">Sold</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                float: "left",
                clear: "none",
                paddingTop: "30px",
                paddingLeft: "50px",
              }}
            >
              {this.mapSection}
            </div>
          </div>
        </div>
        {/* <div
          style={{
            float: "left",
            clear: "none",
            margin: "0 0 0 0",
            height: "100vh",
            width: "45%",
            backgroundColor: "#453960",
          }}
        >
          {this.plotSection}
        </div> */}
        {/* <div
          style={{
            position: "fixed",
            bottom: "30px",
            left: "30px",
            width: "400px",
          }}
        >
          <PlotFilters
            selectedHouseType={this.selectedHouseTypeChangedHandler}
            selectedBedrooms={this.selectedBedroomsChangedHandler}
            selectedPrice={this.selectedPriceChangedHandler}
            selectedArea={this.selectedAreaChangedHandler}
            sharedOwnershipChecked={this.sharedOwnershipChangedHandler}
            helpToBuyChecked={this.helpToBuyChangedHandler}
            resetControls={this.resetControlsHandler}
            houseType={this.state.filterHouseType}
            bedrooms={this.state.filterBedrooms}
            price={this.state.filterPrice}
            area={this.state.filterArea}
            sharedOwnership={this.state.filterSharedOwnership}
            helpToBuy={this.state.filterHelpToBuy}
            propertyDropdpwnOptions={this.props.propertyDropdpwnOptions}
            priceDropdpwnOptions={this.props.priceDropdpwnOptions}
            areaDropdpwnOptions={this.props.areaDropdpwnOptions}
            bedroomsDropdpwnOptions={this.props.bedroomsDropdpwnOptions}
          />
        </div> */}
        {this.state.plotClicked ? (
            <div
              className="plotInfoWindow"
              style={{
                top: `calc(${this.state.cursorY}px + 15px)`,
                left: `calc(${this.state.cursorX}px - 425px)`,
                boxShadow: '0 0 10px #18243F'
              }}
            >
              <div style={{ display: "flex", background: "#fff" }}>
                <div>
                  <img
                    style={{ width: "300px" }}
                    src={this.state.selectedPlot.image}
                    alt=""
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div>
                    <p
                      className="body-GalanoGrotesque"
                      style={{
                        marginTop: "15px",
                        marginLeft: "20px",
                        color: "black",
                      }}
                    >
                      {this.state.selectedPlot.title}
                    </p>
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "10px 25px 0px",
                      color: "black",
                    }}
                    className="body-GalanoGrotesque"
                  >
                    &pound; {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(this.state.selectedPlot.price)}{" "}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 0px",
                      color: "black",
                    }}
                    className="body-GalanoGrotesque"
                  >
                    <img
                      src={require("assets/img/icons/icon_black_bedroom.svg")}
                      alt=""
                      style={{
                        width: "25px",
                        height: "auto",
                        marginRight: "10px",
                        marginLeft: "20px",
                      }}
                    />{" "}
                    {this.state.selectedPlot.bedrooms}{" "}
                    {this.state.selectedPlot.bedrooms > 1
                      ? "bedrooms"
                      : "bedroom"}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "10px 0",
                      color: "black",
                    }}
                    className="body-GalanoGrotesque"
                  >
                    <img
                      src={require("assets/img/icons/icon_black_bathroom.svg")}
                      alt=""
                      style={{
                        width: "25px",
                        height: "auto",
                        marginLeft: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    {this.state.selectedPlot.bathrooms}{" "}
                    {this.state.selectedPlot.bathrooms > 1
                      ? "bathrooms"
                      : "bathroom"}
                  </div>
                </div>
              </div>
              <div className="mapPlotOverlay">
                <div style={{ width: "100%" }}>
                  <Link
                    to={
                      "/plot-overview/" + this.state.selectedPlot.id
                    }
                    className="bold-GalanoGrotesque viewPropsButtonSiteplan"
                    style={{ color: "white", fontSize: "12px", display: 'inline-block' }}
                  >
                    FIND OUT MORE
                  </Link>
                </div>
              </div>
              <div className="plotCloseButton" onClick={this.onClickCloseInfo}>
                x
              </div>
              <div className="plotInfoWindowBefore" style={{backgroundColor: '#fff'}}></div>
            </div>
          ) : null}
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    propertyDropdpwnOptions: state.ftr.propertyDropdpwnOptions,
    priceDropdpwnOptions: state.ftr.priceDropdpwnOptions,
    areaDropdpwnOptions: state.ftr.areaDropdpwnOptions,
    bedroomsDropdpwnOptions: state.ftr.bedroomsDropdpwnOptions
  };
}

export default connect(mapStateToProps)(ApartmentFloorDetail);
