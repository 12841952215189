import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import ApartmentFloorDetail from "../components/ApartmentFloor/ApartmentFloorDetail";
import ImageMapper from "react-image-mapper";
import PrismaZoom from "react-prismazoom";
import SmallMap from "../components/ApartmentFloor/ApartmentFloorSmallMap";
import Aux from "../hoc/Auxiliary/Auxiliary";
import { Link } from "react-router-dom";
import PlotFilters from "../components/Property/PropertyFilters/PlotFilters";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

const imgUrl = window.imageUrl;

class ApartmentFloorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlot: null,
      bathroomLabel: null,
      bedroomLabel: null,
      properties: [],
      floors: [],
      floorsUnfiltered: [],
      propertiesUnfiltered: [],
      floorSelected: false,
      selectedFloor: null,
      filterHouseType: "all",
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      allPlots: [],
      filterreset: false,
      mapReloaded: false,
      blockName: "",
      zoom: 1,
      plotMap: {
        name: "my-map",
        areas: [],
      },
      floorplanStyle: 'normalZoom',
      floorplanZoomWidth: 'normalZoomWidth',
      plotClicked: false
    };
  }


  getPropertiesFromProps() {
    let activeBlock = _.find(this.props.blocks, { id: Number(this.props.match.params.block)});

    if (this.props.properties !== undefined && activeBlock !== undefined) {
      let propProperties = this.props.properties.map((p) => {
        return {
          id: p.id,
          image: p.image,
          bedrooms: p.bedrooms,
          bathrooms: p.bathrooms,
          description: p.description,
          title: p.title,
          name: "plot " + p.id,
          shape: "poly",
          coords:
            p.apartmentFloorCriteria !== null
              ? p.apartmentFloorCriteria.coords
              : null,
          smallmapcoords:
            p.apartmentFloorCriteria !== null
              ? p.apartmentFloorCriteria.smallMapCoords
              : null,
          preFillColor:
            p.apartmentFloorCriteria !== null
              ? 'rgba(239,127,103,0.5)'
              : null,
          fillColor:
            p.apartmentFloorCriteria !== null
              ? 'rgba(239,127,103,0.5)'
              : null,
          houseType: p.houseType,
          price: p.price,
          squareFootage: p.squareFootage,
          sharedOwnership: p.sharedOwnership,
          helpToBuy: p.helpToBuy,
          soPrice: p.soPrice,
          soPriceText: p.soPriceText,//,
          //apartmentBlockID: p.apartmentBlockID
        };
      });

      this.setState({
        properties: propProperties,
        propertiesUnfiltered: propProperties,
      });

      //let activeBlock = _.find(this.props.blocks, { id: Number(this.props.match.params.block)});

      let itemsUnfiltered = activeBlock.floors;

      let items = activeBlock.floors.map((p) => {
        return {
          id: p.id,
          block: "-",
          available_apartments: p.available_apartments,
          smallImageUrl: imgUrl + p.apartment_floor_image_small,
          bigImageUrl: imgUrl + p.apartment_floor_image_large,
          order: p.list_order,
          floorName: p.floor_name,
          plots:
            p.plots !== ""
              ? this.setupPlots(p.plots, propProperties)
              : [],
          plotMap: {
            name: "my-map_" + p.id,
            areas:
              p.plots !== ""
                ? this.setupSmallMap(p.plots, propProperties)
                : [],
          },
        };
      });

      this.setState({
        floorsUnfiltered: itemsUnfiltered,
        floors: items.sort((a, b) => (a.order > b.order ? 1 : -1)),
        mapReloaded: true,
        blockName: activeBlock.title
      });
        
    }
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");

    this.getPropertiesFromProps();
  }

  goBack = () => {
    this.props.history.goBack();
  };

  getFillColor = (status) => {
    switch (status) {
      case "Available":
        return "rgba(0, 107, 103, ";
      case "Sold":
        return "rgba(219, 0, 31, ";
      case "Coming Soon":
        return "rgb(255, 255, 255, ";
      case "Reserved":
        return "rgb(255, 167, 0, ";
      default:
        return "rgba(0, 107, 103, ";
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.mapReloaded === true) {
      this.setState({ mapReloaded: false });
    }

    if (this.props.properties !== prevProps.properties  || this.props.blocks !== prevProps.blocks) {
      this.getPropertiesFromProps();
    }

    if (this.state.filterreset === true) {
      this.setState({ filterreset: false });
    } else {
      if (
        prevState.filterHouseType !== this.state.filterHouseType ||
        prevState.filterBedrooms !== this.state.filterBedrooms ||
        prevState.filterPrice !== this.state.filterPrice ||
        prevState.filterArea !== this.state.filterArea ||
        prevState.filterSharedOwnership !== this.state.filterSharedOwnership ||
        prevState.filterHelpToBuy !== this.state.filterHelpToBuy
      ) {
        const house = this.state.filterHouseType,
          beds = this.state.filterBedrooms,
          price = this.state.filterPrice,
          area = this.state.filterArea,
          ownership = this.state.filterSharedOwnership,
          help = this.state.filterHelpToBuy;

        let items = this.state.floorsUnfiltered.map((p) => {
          return {
            id: p.id,
            block: "-",
            available_apartments: p.available_apartments,
            smallImageUrl: imgUrl + p.apartment_floor_image_small,
            bigImageUrl: imgUrl + p.apartment_floor_image_large,
            order: p.list_order,
            floorName: p.floor_name,
            plots:
              p.plots !== ""
                ? this.setupPlots(p.plots, this.state.properties)
                : [],
            plotMap: {
              name: "my-map_" + p.id,
              areas:
                p.plots !== ""
                  ? this.setupSmallMapFiltered(
                      p.plots,
                      this.state.properties,
                      house,
                      beds,
                      price,
                      area,
                      ownership,
                      help
                    )
                  : [],
            },
          };
        });

        this.setState({
          floors: items.sort((a, b) => (a.order > b.order ? 1 : -1)),
          filterreset: true,
        });
      }
    }
  }

  reDrawCanvas() {
    this._map.current.initCanvas();
  }

  selectedHouseTypeChangedHandler = (ret) => {
    this.setState({
      filterHouseType: ret,
    });
  };
  selectedBedroomsChangedHandler = (ret) => {
    this.setState({
      filterBedrooms: ret,
    });
  };
  selectedPriceChangedHandler = (ret) => {
    this.setState({
      filterPrice: ret,
    });
  };
  selectedAreaChangedHandler = (ret) => {
    this.setState({
      filterArea: ret,
    });
  };
  sharedOwnershipChangedHandler = (ret) => {
    this.setState({
      filterSharedOwnership: ret,
    });
  };
  helpToBuyChangedHandler = (ret) => {
    this.setState({
      filterHelpToBuy: ret,
    });
  };

  resetControlsHandler = () => {
    let items = this.state.floorsUnfiltered.map((p) => {
      return {
        id: p.id,
        block: "-",
        available_apartments: p.available_apartments,
        smallImageUrl: imgUrl + p.apartment_floor_image_small,
        bigImageUrl: imgUrl + p.apartment_floor_image_large,
        order: p.list_order,
        floorName: p.floor_name,
        plots:
          p.plots !== ""
            ? this.setupPlots(p.plots, this.state.properties)
            : [],
        plotMap: {
          name: "my-map_" + p.id,
          areas:
            p.plots !== ""
              ? this.setupSmallMap(p.plots, this.state.properties)
              : [],
        },
      };
    });

    this.setState({
      floors: items.sort((a, b) => (a.order > b.order ? 1 : -1)),
      filterreset: true,
      filterHouseType: "all",
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
    });
  };

  setupPlots = (plots, properties) => {
    let items = [];

    plots.forEach((plotid) => {
      properties.forEach((p) => {
        if (p.id === plotid) {
          items.push(p);
        }
      });
    });

    return items;
  };

  setupSmallMap = (plots, properties) => {
    let items = [];

    plots.forEach((plotid) => {
      properties.forEach((p) => {
        if (p.id === plotid && p.smallmapcoords !== null) {
          items.push({
            id: p.id,
            shape: "poly",
            coords: p.smallmapcoords,
            preFillColor: "#ef7f67",
            fillColor: "#ef7f67",
            houseType: p.houseType,
            bedrooms: p.bedrooms,
            price: p.price,
            squareFootage: p.squareFootage,
            sharedOwnership: p.sharedOwnership,
            helpToBuy: p.helpToBuy,
          });
        }
      });
    });

    return items;
  };

  setupSmallMapFiltered = (
    plots,
    properties,
    house,
    beds,
    price,
    area,
    ownership,
    help
  ) => {
    let items = [];

    let filtered = [...properties];
    let filtered1 = [...properties];
    let filtered2 = [...properties];

    if (ownership === true && help === true) {
      filtered1 = [
        ...filtered.filter(function (el) {
          return el.sharedOwnership === true && el.helpToBuy === true;
        }),
      ];
    }

    if (ownership === true && help === false) {
      filtered1 = [
        ...filtered.filter(function (el) {
          return el.sharedOwnership === true;
        }),
      ];
    }

    if (ownership === false && help === true) {
      filtered1 = [
        ...filtered.filter(function (el) {
          return el.helpToBuy === true;
        }),
      ];
    }

    if (house !== "all") {
      filtered2 = [
        ...filtered1.filter(function (el) {
          return (
            el.houseType === house &&
            el.bedrooms >= Number(beds) &&
            ((el.price <= Number(price) && el.soPrice === "") || (el.soPrice <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
            el.squareFootage > Number(area)
          );
        }),
      ];
    }

    if (house === "all") {
      filtered2 = [
        ...filtered1.filter(function (el) {
          return (
            el.bedrooms >= Number(beds) &&
            ((el.price <= Number(price) && el.soPrice === "") || (el.soPrice <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === ""))// &&
            //el.squareFootage > Number(area)
          );
        }),
      ];
    }

    plots.forEach((plotid) => {
      filtered2.forEach((p) => {
        if (p.id === plotid && p.smallmapcoords !== null) {
          items.push({
            id: p.id,
            shape: "poly",
            coords: p.smallmapcoords,
            preFillColor: "#ef7f67",
            fillColor: "#ef7f67",
            houseType: p.houseType,
            bedrooms: p.bedrooms,
            price: p.price,
            soPrice: p.soPrice,
            soPriceText: p.soPriceText,
            squareFootage: p.squareFootage,
            sharedOwnership: p.sharedOwnership,
            helpToBuy: p.helpToBuy,
          });
        }
      });
    });

    return items;
  };

  floorsSection = null;
  selectedFloorSection = null;
  floorDivs = null;

  getSelectedPlot = (property) => {
    this.refs.prismaZoom.reset();
    this.setState({
      selectedPlot: property,
      bathroomLabel: property.bathrooms > 1 ? "bathrooms" : "bathroom",
      bedroomLabel: property.bedrooms > 1 ? "bedrooms" : "bedroom",
      floorplanStyle: 'normalZoom', 
      floorplanZoomWidth: 'normalZoomWidth',
      zoom: 1,
      plotClicked: true
    });
  };

  selectFloorHandler = (f) => {
    if (f.plots.length > 0) {
      this.setState({
        floorSelected: true,
        selectedFloor: f,
      });
    }
  };

  toggleFloorSelected = () => {
    this.setState({
      floorSelected: false,
      selectedFloor: null,
    });
  };

  getSelectedPlot = (property) => {
    this.refs.prismaZoom.reset();
    this.setState({
      selectedPlot: property,
      bathroomLabel: property.bathrooms > 1 ? "bathrooms" : "bathroom",
      bedroomLabel: property.bedrooms > 1 ? "bedrooms" : "bedroom",
      floorplanStyle: 'normalZoom', 
      floorplanZoomWidth: 'normalZoomWidth',
      zoom: 1,
      plotClicked: true
    });
  };

  clicked(area, evt) {
    this.setState({
      msg:
        "You clicked on " +
        area.shape +
        " at coords " +
        JSON.stringify(area.coords) +
        " !",
        cursorX: evt.clientX,
        cursorY: evt.clientY,
    });

    this.getSelectedPlot(area);
  }

  onClickCloseInfo = (event) => {
    this.setState({ plotClicked: false, selectedPlot: "" });
  };

  render() {
    if (this.state.floorSelected === false && this.state.floors.length > 0) {

      this.selectedFloorSection = null;
      let floorsSectionDivs = this.state.floors.map((p, index) => {
        let items = [];
        let _mapitems = {};
        p.plots.forEach((element) => {
          if (element.coords !== null) {
            if (Number(element.bedrooms) >= Number(this.state.filterBedrooms) && ((Number(element.price) <= Number(this.state.filterPrice) && element.soPrice === "") || (Number(element.soPrice) <= Number(this.state.filterPrice) && element.price === "") || (element.price === "" && element.soPrice === ""))) {
              items.push(element);
            }
          }
        });

        _mapitems = {
          name: "my-map-" + p.floorName,
          areas: items,
        }

        return (
          <div key={index} className={this.state.blockName === "Wilson House" ? "div-selectable col-md-6" : "div-selectable col-md-4"} style={{ textAlign: "center", marginBottom: '00px' }}>
            <p className="bold-GalanoGrotesque"style={{fontSize: "24px",marginTop: "6px",color: "#37596b" }}>{p.floorName}</p>
            <PrismaZoom
              maxZoom={2}
              // onZoomChange={this.onZoomChange}
              ref="prismaZoom"
              zoom={this.state.zoom}
              leftBoundary={310}
              topBoundary={210}
              style={{ padding: '20px 0px', paddingBottom: '0px', marginLeft: this.state.blockName === "Wilson House" ? '50px' : '50px'}}
            >
              <ImageMapper
                ref={this._map}
                src={p.smallImageUrl}
                map={_mapitems}
                // onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                // onImageMouseMove={(evt) => this.moveOnImage(evt)}
                onClick={(area, _, evt) => this.clicked(area, evt)}
              />
            </PrismaZoom>
            <p className="bold-GalanoGrotesque" style={{fontSize: "28px",marginBottom: "27px",color: "#37596b"}}>{p.available_apartments} Available</p>
          </div>
          // <div key={index} onClick={() => { this.selectFloorHandler(p); }} className="div-selectable col-md-4" style={{ textAlign: "center", marginBottom: '50px' }}>
          //   <p className="bold-GalanoGrotesque"style={{fontSize: "14px",marginTop: "6px",color: "#000",textTransform: "uppercase",}}>{p.floorName}</p>
          //   <SmallMap smallImageUrl={p.smallImageUrl} map={p.plotMap} />
          //   <p className="bold-GalanoGrotesque" style={{fontSize: "13px",marginBottom: "27px",color: "#18243F",textTransform: "uppercase"}}>{p.available_apartments} AVAILABLE</p>
          // </div>
        );
      });

      this.floorsSection = (
        <Aux>
          <span
            className="bold-GalanoGrotesque"
            style={{
              fontSize: '47px',
              fontWeight: 'bold',
              color: '#18243F',
              textAlign: 'center',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 99999,
              marginTop: '32px'
            }}
          >
            {this.state.blockName}
          </span>
          <div
            style={{
              position: "fixed",
              top: "23px",
              left: "120px",
              zIndex: "4000",
              width: "200px",
            }}
          >
            {
              <button
                className="lowerButtonNarrowTransparent"
                onClick={() => {
                  this.goBack();
                }}
                style={{ width: "130px", color: "#18243F", marginTop: '23px' }}
              >
                <img
                  src={require("assets/img/icons/arrow-left-dark.svg")}
                  alt=""
                  style={{ width: "auto", height: "12px", marginRight: "10px" }}
                />
                <span className="bold-GalanoGrotesque">BACK</span>
              </button>
            }
          </div>
          {/* <div className="home-logo"><img src={window.menu_logo} alt="" /></div> */}
          <Container>
            <Row>
              <Col sm={12} style={{ margin: "175px 0 0 0" }}>
                <div className="row">{floorsSectionDivs}</div>
              </Col>
            </Row>
          </Container>
          {/* <div
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100vw",
              height: "110px",
              backgroundImage:
                "url(" + require("assets/img/pattern_pink.svg") + ")",
              backgroundSize: "cover",
              opacity: "0.3",
            }}
          /> */}
          {/* <div
            style={{
              position: "absolute",
              top: "0px",
              width: "100vw",
              height: "110px",
              backgroundImage:
                "url(" + require("assets/img/pattern_pink.svg") + ")",
              backgroundSize: "cover",
              opacity: "0.3",
            }}
          /> */}
          <div
            style={{
              position: "fixed",
              bottom: "30px",
              left: "30px",
              width: "400px",
              zIndex: "1",
            }}
          >
            <PlotFilters
              selectedHouseType={this.selectedHouseTypeChangedHandler}
              selectedBedrooms={this.selectedBedroomsChangedHandler}
              selectedPrice={this.selectedPriceChangedHandler}
              selectedArea={this.selectedAreaChangedHandler}
              sharedOwnershipChecked={this.sharedOwnershipChangedHandler}
              helpToBuyChecked={this.helpToBuyChangedHandler}
              resetControls={this.resetControlsHandler}
              houseType={this.state.filterHouseType}
              bedrooms={this.state.filterBedrooms}
              price={this.state.filterPrice}
              area={this.state.filterArea}
              sharedOwnership={this.state.filterSharedOwnership}
              helpToBuy={this.state.filterHelpToBuy}
              propertyDropdpwnOptions={this.props.propertyDropdpwnOptions}
              priceDropdpwnOptions={this.props.priceDropdpwnOptions}
              areaDropdpwnOptions={this.props.areaDropdpwnOptions}
              bedroomsDropdpwnOptions={this.props.bedroomsDropdpwnOptions}
            />
          </div>
        </Aux>
      );
    }

    if (
      this.state.floorSelected === true &&
      this.state.selectedFloor !== null
    ) {
      this.floorsSection = null;

      this.selectedFloorSection = (
        <Aux>
          <span
            className="bold-GalanoGrotesque"
            style={{
              fontSize: '47px',
              fontWeight: 'bold',
              color: '#18243F',
              textAlign: 'center',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 99999,
              marginTop: '32px'
            }}
          >
            {this.state.selectedFloor.floorName}
          </span>
          <div
            style={{
              position: "fixed",
              top: "23px",
              left: "120px",
              zIndex: "4000",
              width: "200px",
            }}
          >
            {
              <button
                className="lowerButtonNarrowTransparent"
                onClick={this.toggleFloorSelected}
                style={{ width: "130px", color: "#18243F", marginTop: '23px' }}
              >
                <img
                  src={require("assets/img/icons/arrow-left-dark.svg")}
                  alt=""
                  style={{ width: "auto", height: "12px", marginRight: "10px" }}
                />
                <span className="bold-GalanoGrotesque">BACK</span>
              </button>
            }
          </div>
          <ApartmentFloorDetail floor={this.state.selectedFloor} />
        </Aux>
      );
    }

    return (
      <Fragment>
        <div
          className="apartBack"
          style={{
            margin: "0px",
            width: "100vw",
            height: "100vh"
          }}
        >
          {this.selectedFloorSection}
          {this.floorsSection}
          {this.state.plotClicked ? (
            <div
              className="plotInfoWindow"
              style={{
                top: `calc(${this.state.cursorY}px + 15px)`,
                left: `calc(${this.state.cursorX}px - 425px)`,
                boxShadow: '0 0 10px #18243F',
                height: '224px'
              }}
            >
              <div style={{ display: "flex", background: "#fff" }}>
                <div>
                  <img
                    style={{ width: "300px" }}
                    src={this.state.selectedPlot.image}
                    alt=""
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div>
                    <p
                      className="body-GalanoGrotesque"
                      style={{
                        marginTop: "15px",
                        marginLeft: "20px",
                        color: "black",
                      }}
                    >
                      {this.state.selectedPlot.title}
                    </p>
                  </div>
                  {this.state.selectedPlot.price !== "" ?
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "10px 20px 0px",
                      color: "black",
                    }}
                    className="body-GalanoGrotesque"
                  >
                    &pound; {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(this.state.selectedPlot.price)}{" "}
                  </div> : 
                  <div
                  style={{
                    fontSize: "14px",
                    margin: "10px 20px 0px",
                    color: "black",
                  }}
                  className="body-GalanoGrotesque"
                >
                  &pound; {new Intl.NumberFormat('en-GB', { maximumSignificantDigits: 3 }).format(this.state.selectedPlot.soPrice)}{" "}
                  {this.state.selectedPlot.soPriceText}
                </div>}
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "10px 0px 0px",
                      color: "black",
                    }}
                    className="body-GalanoGrotesque"
                  >
                    <img
                      src={require("assets/img/icons/icon_black_bedroom.svg")}
                      alt=""
                      style={{
                        width: "25px",
                        height: "auto",
                        marginRight: "10px",
                        marginLeft: "20px",
                      }}
                    />{" "}
                    {this.state.selectedPlot.bedrooms}{" "}
                    {this.state.selectedPlot.bedrooms > 1
                      ? "bedrooms"
                      : "bedroom"}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      margin: "10px 0",
                      color: "black",
                    }}
                    className="body-GalanoGrotesque"
                  >
                    <img
                      src={require("assets/img/icons/icon_black_bathroom.svg")}
                      alt=""
                      style={{
                        width: "25px",
                        height: "auto",
                        marginLeft: "20px",
                        marginRight: "10px",
                      }}
                    />{" "}
                    {this.state.selectedPlot.bathrooms}{" "}
                    {this.state.selectedPlot.bathrooms > 1
                      ? "bathrooms"
                      : "bathroom"}
                  </div>
                </div>
              </div>
              <div className="mapPlotOverlay">
                <div style={{ width: "100%" }}>
                  <Link
                    to={
                      "/plot-overview/" + this.state.selectedPlot.id
                    }
                    className="bold-GalanoGrotesque viewPropsButtonSiteplan"
                    style={{ color: "white", fontSize: "12px", display: 'inline-block' }}
                  >
                    FIND OUT MORE
                  </Link>
                </div>
              </div>
              <div className="plotCloseButton" onClick={this.onClickCloseInfo}>
                x
              </div>
              <div className="plotInfoWindowBefore" style={{backgroundColor: '#fff'}}></div>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertyDropdpwnOptions: state.ftr.propertyDropdpwnOptions,
    priceDropdpwnOptions: state.ftr.priceDropdpwnOptions,
    areaDropdpwnOptions: state.ftr.areaDropdpwnOptions,
    bedroomsDropdpwnOptions: state.ftr.bedroomsDropdpwnOptions,
    properties: state.prop.properties,
    blocks: state.blk.apartmentBlocks
  };
};

export default connect(mapStateToProps)(withRouter(ApartmentFloorPage));
