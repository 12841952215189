import React from "react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./ReactCarouselGallery.css"

class ReactCarouselGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onchange = this.onchange.bind(this);
  }

  onchange(value) {
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {}

  componentWillMount() {}

  render() {
    return (
        <div
          style={{
            margin: '0px',
            height: "70vh",
            paddingTop: '40px',
            border: '0px #2f234e solid'
          }}
        >
        <Carousel
        animationSpeed={1000} 
         centered
          slidesPerPage={1} 
          value={this.state.value}
          onChange={this.onchange}
          infinite
          arrowLeft={<div style={{ position: "absolute", right: "135px", bottom: "50px", zIndex: "6000"  }}><img src={require("assets/img/icons/galleryLeftBlue.png")} style={{ width: '75px', marginTop: '17px', height: '75px', marginLeft: '13px' }} alt="more" /></div>}
          arrowLeftDisabled={<div style={{ position: "absolute", right: "135px", bottom: "50px", zIndex: "6000" }}><img src={require("assets/img/icons/galleryLeftBlue.png")} style={{ width: '75px', marginTop: '17px', height: '75px', marginLeft: '13px' }} alt="more" /></div>}
          arrowRight={<div style={{ position: "absolute", right: "50px", bottom: "50px", zIndex: "6000" }}><img src={require("assets/img/icons/galleryRightBlue.png")} style={{ width: '75px', marginTop: '17px', height: '75px', marginLeft: '19px' }} alt="more" /></div>}
          arrowRightDisabled={<div style={{ position: "absolute", right: "50px", bottom: "50px", zIndex: "6000" }}><img src={require("assets/img/icons/galleryRightBlue.png")} style={{ width: '75px', marginTop: '17px', height: '75px', marginLeft: '19px' }} alt="more" /></div>}
          keepDirectionWhenDragging
          addArrowClickHandler
          >
          {this.props.slides.map((item) => {
            return (
              <div key={item.id}>
                <img className="galleryImage"
                  style={{
                    height: "100vh",
                    borderRadius: "0px",
                    objectFit: "contain",
                    width: "100vw",
                    background: `rgba(0,0,0,0.7)`
                    // borderLeft: '20px #362b54 solid',
                    // borderRight: '20px #362b54 solid'
                  }}
                  src={item.src}
                  alt={item.altText}
                />
                {/* <div className="imageTextOverlay">
                  {item.caption}
                </div> */}
              </div>
            );
          })}
        </Carousel>
        {/* <div
          style={{
            position: "absolute",
            bottom: "50px",
            width: "100%",
          }}
        >
          <Dots
            value={this.state.value}
            onChange={this.onchange} 
            number={this.props.slides.length}
          />
        </div> */}
      </div>
    );
  }
}
export default ReactCarouselGallery;
