export const FETCH_FILTER_SETTINGS = 'FETCH_FILTER_SETTINGS';
export const FETCH_FILTER_SETTINGS_FAILED = 'FETCH_FILTER_SETTINGS_FAILED';
export const FETCH_DEVELOPMENT = 'FETCH_DEVELOPMENT';
export const FETCH_DEVELOPMENT_FAILED = 'FETCH_DEVELOPMENT_FAILED';
export const FETCH_PROPERTIES = 'FETCH_PROPERTY';
export const FETCH_PROPERTIES_FAILED = 'FETCH_PROPERTY_FAILED';
export const FETCH_NAVIGATION = 'FETCH_NAVIGATION';
export const FETCH_NAVIGATION_FAILED = 'FETCH_NAVIGATION_FAILED';
export const FETCH_DEV_STYLES = 'FETCH_DEV_STYLES';
export const FETCH_DEV_STYLES_FAILED = 'FETCH_DEV_STYLES_FAILED';
export const FETCH_FLOORS = 'FETCH_FLOORS';
export const FETCH_FLOORS_FAILED = 'FETCH_FLOORS_FAILED';
export const FETCH_SITE_PAGES = 'FETCH_SITE_PAGES';
export const FETCH_SITE_PAGES_FAILED = 'FETCH_SITE_PAGES_FAILED';
export const FETCH_BLOCKS = 'FETCH_BLOCKS';
export const FETCH_BLOCKS_FAILED = 'FETCH_BLOCKS_FAILED';
export const FETCH_ABOUT_CATALYST = 'FETCH_ABOUT_CATALYST';
export const FETCH_ABOUT_CATALYST_FAILED = 'FETCH_ABOUT_CATALYST_FAILED';
export const FETCH_BUYING_SCHEME = 'FETCH_BUYING_SCHEME';
export const FETCH_BUYING_SCHEME_FAILED = 'FETCH_BUYING_SCHEME_FAILED';

export const PROPERTY_UPDATE_SUCCESS = 'PROPERTY_UPDATE_SUCCESS';
export const PROPERTY_UPDATE_FAILED = 'PROPERTY_UPDATE_FAILED';


