import React from "react";
import ImageMapper from "react-image-mapper";

class ApartmentFloorSmallMap extends React.Component{

    constructor(props) {
        super(props);
        this._map = React.createRef();
    }
    
    componentDidUpdate(prevProps, prevState) {

        if(prevProps !== this.props) {
            this.reDrawCanvas();
        }
    }

    reDrawCanvas() {
        this._map.current.initCanvas();
      }

    render(){

        return (
            <div style={{ marginLeft: '20px' }}>
               <ImageMapper ref={this._map}
                src={this.props.smallImageUrl}
                map={this.props.map}
            />
            </div>  
        );
    }; 
};

export default ApartmentFloorSmallMap;
