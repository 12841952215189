import React from "react";

const fourImageSection = (props) => (
  <div className="row" style={{ padding: "0px", margin: "0px", width: "100%" }}>
  <div
    className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
    style={{ padding: "0px" }}
  >
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[0].id);
        }}
        src={props.images[0].src}
        style={{ width: "100%", height: "50vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[1].id);
        }}
        src={props.images[1].src}
        style={{ width: "100%", height: "50vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
  </div>
  <div
    className="col-lg-6 col-md-6 col-sm-6 col-xs-6"
    style={{ padding: "0px" }}
  >
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[2].id);
        }}
        src={props.images[2].src}
        style={{ width: "100%", height: "50vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
    <div style={{ width: "100%" }}>
      <img
        onClick={() => {
          props.openCarousel(props.images[3].id);
        }}
        src={props.images[3].src}
        style={{ width: "100%", height: "50vh", objectFit: "cover" }}
        alt=""
        className="carouselImages"
      />
    </div>
  </div>
</div>
  );
  
  export default fourImageSection;
