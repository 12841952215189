import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const fetchFilterSettings = () => {

    return dispatch => {

        axios
        .get("/wp-json/wp/v2/filter_settings")
        .then((response) => {
            dispatch(dispatchedFilterSettings(response.data));
        })
        .catch((error) => {
            dispatch(fetchFilterSettingsFailed());
        });
    }
};

export const fetchFilterSettingsFailed = () => {
    return {
        type: actionTypes.FETCH_FILTER_SETTINGS_FAILED
    };
}

export const dispatchedFilterSettings = (data) => {


      let propertyItems = data[0].acf.property_types.map(
        (p, index) => {
          return {
            id: index,
            value: p.value,
            display: p.display,
          };
        }
      );

      let priceItems = data[0].acf.maximum_price.map((p, index) => {
        return {
          id: index,
          value: p.value,
          display: p.display,
        };
      });

      let priceMinItems = data[0].acf.minimum_price.map((p, index) => {
        return {
          id: index,
          value: p.value,
          display: p.display,
        };
      });

      let areaItems = data[0].acf.minimum_square_footage.map(
        (p, index) => {
          return {
            id: index,
            value: p.value,
            display: p.display,
          };
        }
      );

      let bedrooms = data[0].acf.bedrooms.map(
        (p, index) => {
          return {
            id: index,
            value: p.value,
            display: p.display,
          };
        }
      );

      let availability = data[0].acf.availability.map(
        (p, index) => {
          return {
            id: index,
            value: p.value,
            display: p.display,
          };
        }
      );
    
    console.log(priceMinItems);
    
    return {
        type: actionTypes.FETCH_FILTER_SETTINGS,
        propertyDropdpwnOptions: propertyItems,
        priceDropdpwnOptions: priceItems,
        priceMinDropdpwnOptions: priceMinItems,
        areaDropdpwnOptions: areaItems,
        bedroomsDropdpwnOptions: bedrooms,
        availabilityDropdpwnOptions: availability
    };
};