import React, { Fragment } from "react";
import axios from "../axios";
import FormBuilder from "../components/Form/FormBuilder/FormBuilder";
import "./RegisterPage.css";

import {
  Container,
  Row,
  Button,
} from "reactstrap";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      gravityForm: [],
      formFields: "",
      formValid: false,
      buyingSchemes: [],
      marketingPrefs: [],
      formSubmitted: false,
      gravityFields: [],
      totalRequiredFields: 0,
      requiredFieldsSelected: 0,
    };
  }

  validatePhone = (phonenumber) => {
    const patt1 = new RegExp(
      /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|#)\d+)?)$/
    );
    const patt2 = new RegExp(
      /^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)(44)\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)([1-9]\d{1,4}\)?[\s\d-]+)(?:((?:x|ext\.?\s?|#)\d+)?)$/
    );

    let validNumber = patt1.test(phonenumber);

    if (validNumber) {
      validNumber = patt2.test(phonenumber);
    }

    return validNumber;
  };

  validateEmail = (emailAddress) => {
    const pattern = new RegExp(
      /^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/
    );
    return pattern.test(emailAddress);
  };

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   prevState.totalRequiredFields !== this.state.totalRequiredField ||
    //   prevState.requiredFieldsSelected !== this.state.requiredFieldsSelected
    // ) {
    //   this.setState({
    //     formValid:
    //       this.state.totalRequiredField === this.state.requiredFieldsSelected,
    //   });
    // }
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");

    var d = new Date();
    var expiration = 3600; // 1 hour,
    var unixtime = parseInt(d.getTime() / 1000);
    var future_unixtime = unixtime + expiration;
    var publicKey = "b42ebf253b";
    var privateKey = "8c47f3a2e0e746e";
    var method = "GET";
    var route = "forms/1";

    var stringToSign =
      publicKey + ":" + method + ":" + route + ":" + future_unixtime;
    var sig = this.CalculateSig(stringToSign, privateKey);
    this.state.url =
      // "http://localhost:8080/gravityformsapi/" +
      "http://newmanplaceapp.local.test/app/gravityformsapi/" +
      //"https://thelanesapp.focus-pluto.co.uk/app/gravityformsapi/" +
      route +
      "?api_key=" +
      publicKey +
      "&signature=" +
      sig +
      "&expires=" +
      future_unixtime;
    fetch(this.state.url)
      .then((res) => res.json())
      .then(
        (result) => {
          //this.buildForm(result.response);
          if (result.response.fields !== null) {
            this.setState({ gravityFields: result.response.fields });

            let totalRequired = 0;

            result.response.fields.forEach((element) => {
              let inputName = "input_" + element.id;
              if (element.type === "checkbox") inputName = inputName + "_1";
              this.setState({
                [inputName]: {
                  id: element.id,
                  type: element.type,
                  value: null,
                  required: element.isRequired,
                  completed: false,
                },
              });
              if (element.isRequired === true) {
                totalRequired += 1;
              }
            });

            this.setState({
              totalRequiredFields: totalRequired,
              formValid: totalRequired === 0,
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  formSection = null;
  builtForm = null;

  formResetHandler = () => {
    this.state.gravityFields.forEach((element) => {
      let inputName = "input_" + element.id;
      if (element.type === "checkbox") inputName = inputName + "_1";
      this.setState({
        [inputName]: {
          id: element.id,
          type: element.type,
          value: null,
          required: element.isRequired,
          completed: false,
        },
      });
    });

    // this.setState({
    //   formValid: false,
    //   buyingSchemes: [],
    //   marketingPrefs: [],
    //   formSubmitted: false,
    //   requiredFieldsSelected: 0,
    // });

    window.location.reload(false);
  };

  formSubmitHandler = (event) => {
    event.preventDefault();
    let formInput = {};
    let input_values = {};

    this.state.gravityFields.forEach((p) => {
      let inputName = "input_" + p.id;
      if (p.type === "checkbox") inputName = inputName + "_1";

      input_values[inputName] = this.state[inputName].value;
    });

    formInput.input_values = input_values;

    axios
      .post(
        //"https://thelanesapp.focus-pluto.co.uk/app/gravityformsapi/forms/1/submissions",
        "http://newmanplaceapp.local.test/app/gravityformsapi/forms/1/submissions",
        // "http://localhost:8080/gravityformsapi/forms/1/submissions",
        formInput
      )
      .then((response) => {
      })
      .catch((error) => {
      });

    this.setState({ formSubmitted: true });
  };

  inputChangedHandler = (event, inputIdentifier, type) => {

    let countRequiredSelected = this.state.requiredFieldsSelected;
    const totalRequiredSelected = this.state.totalRequiredFields;

    let stateInput = { ...this.state[inputIdentifier] };
    let stateInputRequired = stateInput.required;
    let stateInputCompleted = stateInput.completed;
    let thisInputCompleted = false;
    let inputValue = "";

    if (type === "checkbox") {

      let ary1 = stateInput.value !== null ? stateInput.value.split(",") : [];
      if (event.target.checked === true) {
        ary1.push(event.target.value);
      } else {
        const index1 = ary1.indexOf(event.target.value);
        if (index1 > -1) {
          ary1.splice(index1, 1);
        }
      }

      inputValue = ary1.toString();
    }

    if (type !== "checkbox") {
      inputValue = event.target.value;
    }

    if (type === "email") {
      thisInputCompleted = this.validateEmail(inputValue);
    }

    if (type === "phone") {
      thisInputCompleted = this.validatePhone(inputValue);
    }

    if (type !== "email" && type !== "phone") {
      thisInputCompleted = inputValue.length > 0;
    }

    if (stateInputRequired) {
      if (stateInputCompleted && !thisInputCompleted) {
        countRequiredSelected = countRequiredSelected - 1;
      }
      if (!stateInputCompleted && thisInputCompleted) {
        countRequiredSelected = countRequiredSelected + 1;
      }
    }

    this.setState({
      [inputIdentifier]: {
        id: stateInput.id,
        type: stateInput.type,
        value: inputValue,
        required: stateInput.required,
        completed: thisInputCompleted,
      },
    });

    this.setState({ requiredFieldsSelected: countRequiredSelected });

    if (countRequiredSelected >= totalRequiredSelected) {
      this.setState({ formValid: true });
    }

    if (countRequiredSelected < totalRequiredSelected) {
      this.setState({ formValid: false });
    }
  };

  CalculateSig(stringToSign, privateKey) {
    /*global CryptoJS*/
    var hash = CryptoJS.HmacSHA1(stringToSign, privateKey);
    var base64 = hash.toString(CryptoJS.enc.Base64);
    return encodeURIComponent(base64);
  }

  componentWillMount() {}

  render() {

    if (this.state.gravityFields.length > 0) {
      this.builtForm = (
        <FormBuilder
          inputChanged={this.inputChangedHandler}
          fields={this.state.gravityFields}
        />
      );
    }

    if (this.state.formSubmitted === false && this.builtForm !== null) {
      this.formSection = (
        <form onSubmit={this.formSubmitHandler}>
          <Container style={{ textAlign: "left", width: "40%" }}>
            <div className="form-row">{this.builtForm}</div>
            <div className="form-row">
              <div
                className="form-group col-md-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  disabled={!this.state.formValid}
                  style={{
                    backgroundColor: "#c2a688",
                    border: "1px solid #c2a688",
                    borderRadius: "0px",
                    width: "250px",
                    padding: "18px",
                    color: "#3c371a",
                  }}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </Container>
        </form>
      );
    }
    if (this.state.formSubmitted === true) {
      this.builtForm = null;
      this.formSection = (
        <div>
          <p
            style={{
              color: "white",
              padding: "50px 120px",
              textAlign: "center",
              fontWeight: "400",
            }}
          >
            Thank you
          </p>
          <Button
            onClick={this.formResetHandler}
            style={{
              backgroundColor: "#c2a688",
              border: "1px solid #c2a688",
              borderRadius: "0px",
              width: "250px",
              padding: "18px",
              color: "#3c371a",
            }}
          >
            BACK TO FORM
          </Button>
        </div>
      );
    }

    return (
      <Fragment>
        <Container
        className="interest-background"
          style={{
            maxWidth: "100%",
            width: "100%",
            padding: "0px",
          }}
        >
          <Row style={{ margin: "0px" }}>
            <div
              style={{
                width: "40%",
                textAlign: "center",
                marginTop: "215px",
                marginBottom: "60px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <p
                style={{
                  color: "#FFFFFF",
                  fontWeight: "normal",
                  fontSize: "24px",
                  lineHeight: "1.2em",
                  padding: "0",
                }}
              >
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.
              </p>
            </div>
          </Row>
          <Row
            style={{
              margin: "0px",
              width: "100vw",
              textAlign: "center",
              height: "72vh",
            }}
          >
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "10px" }}
            >
              {this.formSection}
            </div>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export default RegisterPage;
