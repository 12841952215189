import React from "react";

import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";

class AboutCatalyst extends React.Component {
  render() {
    return (
      <>
        <Row style={{ margin: '0px' }}>
            <Col sm={12} id="carousel" style= {{ padding: '0px' }}>
              <Container style={{ margin: '0px', maxWidth: '100%'}}>
                <Row>
                  <Col className="page-header ml-auto mr-auto" style={{ padding: '0px'}}>
                    {this.props.aboutCatalyst && 
                      <Card className="page-carousel no-radius" style={{ height: '100%', marginBottom: '0px', backgroundImage: 'url(' + this.props.aboutCatalyst.background_image + ')'}}>
                        <Row class="aboutCardHeight">
                        <Col lg={4} md={12} sm={12}></Col>
                        <Col lg={8} md={12} sm={12} className="about-card">
                          <div className="about-card__content">
                            <Row>
                              <Col lg={2} md={2} sm={12} id="carousel" style= {{ padding: '0px' }}>
                                <img style={{ margin: '0 auto'}} src={this.props.aboutCatalyst.logo} alt="" />
                              </Col>
                              <Col sm={10} id="carousel" className="heading d-flex align-items-center" style= {{ padding: '0px', marginTop: '20px' }}>
                              <div dangerouslySetInnerHTML={{__html: this.props.aboutCatalyst.bold_section}} />
                              </Col>
                              <h3>{this.props.aboutCatalyst.title}</h3>
                              <div dangerouslySetInnerHTML={{__html: this.props.aboutCatalyst.content}} />
                            </Row>
                            <Row style={{ marginTop: '30px'}}>
                              <Col sm={4} id="carousel" className="columns" style= {{ padding: '10px' }}>
                                <div dangerouslySetInnerHTML={{__html: this.props.aboutCatalyst.column_one}} />
                              </Col>
                              <Col sm={4} id="carousel" className="columns" style= {{ padding: '10px' }}>
                                <div dangerouslySetInnerHTML={{__html: this.props.aboutCatalyst.column_two}} />
                              </Col>
                              <Col sm={4} id="carousel" className="columns" style= {{ padding: '10px' }}>
                                <div dangerouslySetInnerHTML={{__html: this.props.aboutCatalyst.column_three}} />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        </Row>
                        
                      </Card>
                    }
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    aboutCatalyst: state.cat.aboutCatalyst
  };
}

export default connect(mapStateToProps)(AboutCatalyst);
