import React, { Component, Fragment } from "react";
// import { Link } from "react-router-dom";
import Aux from "../hoc/Auxiliary/Auxiliary";
import Property from "../components/Property/Property";
import PropertyFilters from "../components/Property/PropertyFilters/PropertyFilters";
import PlotFilters from "../components/Property/PropertyFilters/PlotFilters";
import CompareProperty from "../components/Property/CompareProperty/CompareProperty";
import { Redirect } from "react-router-dom";
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import _ from "lodash";
import {
  Container,
  Row,Col
} from "reactstrap";
import * as actionCreators from "../store/actions/index";
import { TextInputField, Spinner, Pane, Button, LinkIcon, ShareIcon } from 'evergreen-ui';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from "../axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShare, faTimes } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width: '300px'
  }
};

var subtitle;

class Favourites extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
    this.state = {
      favouriteProps: [],
      favouritePropsUnfiltered: [],
      showCompare: false,
      propertyCompare: [],
      compareButtonActive: true,
      maxCompareSelected: false,
      scrollPosition: 0,
      filterHouseType: "all",
      filterAvailability: 'All',
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterMinPrice: 100000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      showFormSubmitting: false,
      selectedPlotId: ""
    };
  }

  propertyItems = null;
  compareSection = null;
  listingSection = null;
  noPropertiesMessage = "";


  componentDidUpdate(prevProps, prevState) {
    if (this.props.favouriteProps !== prevProps.favouriteProps) {
      if(this.props.favouriteProps !== undefined) {
        this.setState({ favouriteProps: this.props.favouriteProps,
            favouritePropsUnfiltered: this.props.favouriteProps,
            showFormSubmitting: false
          });
      }
    }

    if (
      prevState.filterHouseType !== this.state.filterHouseType ||
      prevState.filterBedrooms !== this.state.filterBedrooms ||
      prevState.filterPrice !== this.state.filterPrice ||
      prevState.filterMinPrice !== this.state.filterMinPrice ||
      prevState.filterArea !== this.state.filterArea ||
      prevState.filterSharedOwnership !== this.state.filterSharedOwnership ||
      prevState.filterHelpToBuy !== this.state.filterHelpToBuy ||
      prevState.filterAvailability !== this.state.filterAvailability
    ) {

      this.noPropertiesMessage = "No Results";

      const house = this.state.filterHouseType,
        beds = this.state.filterBedrooms,
        price = this.state.filterPrice,
        priceMin = this.state.filterMinPrice,
        area = this.state.filterArea,
        ownership = this.state.filterSharedOwnership,
        availability = this.state.filterAvailability,
        help = this.state.filterHelpToBuy;

      let unfiltered = [...this.state.favouritePropsUnfiltered];

      let filtered = unfiltered;
      let filtered1 = unfiltered;

      if (ownership === true && help === true) {
        filtered = [
          ...unfiltered.filter(function (el) {
            return el.sharedOwnership === true && el.helpToBuy === true;
          }),
        ];
      }

      if (ownership === true && help === false) {
        filtered = [
          ...unfiltered.filter(function (el) {
            return el.sharedOwnership === true;
          }),
        ];
      }

      if (ownership === false && help === true) {
        filtered = [
          ...unfiltered.filter(function (el) {
            return el.helpToBuy === true;
          }),
        ];
      }

      if (house !== "all") {
        filtered1 = [
          ...filtered.filter(function (el) {
            console.log("NOT ALL House: "+el.priceMin);
            return (
              el.houseType === house &&
              el.bedrooms >= Number(beds) &&
              ((el.price <= Number(price) && el.soPrice === "") || (el.soPrice <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
              ((el.price >= Number(priceMin) && el.soPrice === "") || (el.soPrice >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
              el.squareFootage > Number(area)
            );
          }),
        ];
      }

      if (house === "all") {
        if (availability === "All") {
          filtered1 = [
            ...filtered.filter(function (el) {
              return (
                Number(el.bedrooms) >= Number(beds) &&
                ((Number(el.price) <= Number(price) && el.soPrice === "") || (Number(el.soPrice) <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
                ((Number(el.price) >= Number(priceMin) && el.soPrice === "") || (Number(el.soPrice) >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) //&&
                //el.squareFootage > Number(area)
              );
            }),
          ];
        } else {
          filtered1 = [
            ...filtered.filter(function (el) {
              return (
                Number(el.bedrooms) >= Number(beds) &&
                el.status === availability &&
                ((Number(el.price) <= Number(price) && el.soPrice === "") || (Number(el.soPrice) <= Number(price) && el.price === "") || (el.price === "" && el.soPrice === "")) &&
                ((Number(el.price) >= Number(priceMin) && el.soPrice === "") || (Number(el.soPrice) >= Number(priceMin) && el.price === "") || (el.price === "" && el.soPrice === "")) //&&
                //el.squareFootage > Number(area)
              );
            }),
          ];
        }
        
      }

      let items = filtered1
        .filter((house) => house.houseType === 'house').map((p, index) => {
        return {
          propertyIndex: index,
          id: p.id,
          status: p.status,
          image: p.image,
          bedrooms: p.bedrooms,
          bathrooms: p.bathrooms,
          description: p.description,
          title: p.title,
          subtitle: p.subtitle,
          price: p.price,
          soPrice: p.soPrice,
          soPriceText: p.soPriceText,
          roomSizes: p.roomSizes,
          houseType: p.houseType,
          squareFootage: p.squareFootage,
          sharedOwnership: p.sharedOwnership,
          helpToBuy: p.helpToBuy,
          selected: false,
          favourite: p.favourite
        };
      });

      this.setState({ favouriteProps: items });
    }
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");

    if(this.props.favouriteProps !== undefined) {
      
      this.setState({ favouriteProps: this.props.favouriteProps,
        favouritePropsUnfiltered: this.props.favouriteProps
        });
    }

  }

  listenScrollEvent() {
    
    if (this.scrollRef.current !== null) {
      const scr = this.scrollRef.current;
      this.setState({ scrollPosition: scr.scrollLeft });
    }
  }

  regesterInterestClick = () => {
    return <Redirect to="register-interest" />;
  };

  selectedHouseTypeChangedHandler = (ret) => {
    this.setState({
      filterHouseType: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  selectedBedroomsChangedHandler = (ret) => {
    this.setState({
      filterBedrooms: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  selectedPriceChangedHandler = (ret) => {
    this.setState({
      filterPrice: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  selectedMinPriceChangedHandler = (ret) => {
    this.setState({
      filterMinPrice: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  selectedAvailabilityChangedHandler = (ret) => {
    this.setState({
      filterAvailability: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  selectedAreaChangedHandler = (ret) => {
    this.setState({
      filterArea: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  sharedOwnershipChangedHandler = (ret) => {
    this.setState({
      filterSharedOwnership: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };
  helpToBuyChangedHandler = (ret) => {
    this.setState({
      filterHelpToBuy: ret,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };

  resetControlsHandler = () => {
    let unfiltered = [...this.state.favouritePropsUnfiltered];
    this.setState({
      favouriteProps: unfiltered,
      filterHouseType: "all",
      filterAvailability: 'All',
      filterBedrooms: "1",
      filterPrice: 1000000,
      filterMinPrice: 100000,
      filterArea: "0",
      filterSharedOwnership: false,
      filterHelpToBuy: false,
      propertyCompare: [],
      maxCompareSelected: false,
      compareButtonActive: false,
    });
  };

  handleCompareChecked = (props) => {
    if (this.scrollRef.current !== null) {
      const scr = this.scrollRef.current;
      scr.scrollLeft = this.state.scrollPosition;
    }

    let compareArray = [...this.state.propertyCompare];

    if (compareArray.length < 2) {
      let allPropertis = [...this.state.favouriteProps];

      compareArray.push(props);

      allPropertis[props.propertyIndex] = {
        ...allPropertis[props.propertyIndex],
        selected: true,
      };

      this.setState({ propertyCompare: compareArray });
      this.setState({ favouriteProps: allPropertis });

      if (compareArray.length === 2) {
        this.setState({ maxCompareSelected: true });
      }
    }

    console.log(compareArray);
  };

  clearFavourites = () => {
    this.setState({ showFormSubmitting: true });
    this.props.favouriteProps.forEach(element => {
      this.props.handleFavouriteClick("false", element.id);
    });
    
  }

  favouriteClick = (_type, _id) => {
    this.setState({ showFormSubmitting: true });
    this.props.handleFavouriteClick(_type, _id);
  }

  handleCompareUnchecked = (props) => {
    if (this.scrollRef.current !== null) {
      const scr = this.scrollRef.current;
      scr.scrollLeft = this.state.scrollPosition;
    }


    let compareArray = [...this.state.propertyCompare];
    let allPropertis = [...this.state.favouriteProps];

    _.remove(compareArray, function (e) {
      return e.id === props.id;
    });

    allPropertis[props.propertyIndex] = {
      ...allPropertis[props.propertyIndex],
      selected: false,
    };

    this.setState({ propertyCompare: compareArray });
    this.setState({ favouriteProps: allPropertis });
    this.setState({ maxCompareSelected: false });
  };

  toggleCompareProperties = (event) => {
    const prevCompare = !this.state.showCompare;

    this.setState({ showCompare: prevCompare });
  };

  shareClick = (_id) => {
    //this.setState({ isModalOpen: true, value: 'http://localhost:3000/plot-overview/' + _id });
    this.setState({ isModalOpen: true, value: 'https://broadmeadowpark.focus-pluto.co.uk/plot-overview/' + _id, selectedPlotId: _id });
  }

  handleShare = () => {
    if(this.isValid()) {

      this.setState({ showModalSubmitting: true });
      let formInput = {};
      let input_values = {};

      input_values["input_3"] = this.state.yourName;
      input_values["input_17"] = this.state.yourEmail;
      input_values["input_18"] = this.state.friendName;
      input_values["input_16"] = this.state.friendEmail;
      input_values["input_15"] = this.state.selectedPlotId;
  
      formInput.input_values = input_values;
  
      axios
        .post(
          //"http://newmanplaceapp.local.test/app/gravityformsapi/forms/1/submissions",
          //"http://localhost/broadwalkwp/gravityformsapi/forms/3/submissions",
          "https://broadmeadowpark.focus-pluto.co.uk/app/gravityformsapi/forms/3/submissions",
          formInput
        )
        .then((response) => {
          this.setState({ formSubmitted: true, showModalSubmitting: false, isModalOpen: false, yourName: "", yourEmail: "", friendName: "", friendEmail: "", selectedPlotId: "" });
        })
        .catch((error) => {
          this.setState({ formSubmitted: false, showModalSubmitting: false });
        });
    }
  }

  isValid = () => {
    var _yourNameInvalid = false;
    var _yourEmailInvalid = false;
    if (this.state.yourName === '') {
      _yourNameInvalid = true;
      this.setState({ yourNameInvalid: true })
    } else {
      _yourNameInvalid = false;
      this.setState({ yourNameInvalid: false })
    }

    if (this.state.yourEmail === '') {
      _yourEmailInvalid = true;
      this.setState({ yourEmailInvalid: true })
    } else {
      _yourEmailInvalid = false;
      this.setState({ yourEmailInvalid: false })
    }

    if (_yourNameInvalid || _yourEmailInvalid)
      return false;

    return true;
  }

  render() {
    if (this.state.showCompare === true) {
      this.listingSection = null;
      this.compareSection = (
        <Aux>
          <div style={{ background: "#37596b;", marginTop: "130px", padding: "14px" }}>
            <div style={{
              display: 'inline-block',
              verticalAlign: 'middle'
            }}>
              <button
                className="lowerButtonNarrowTransparent"
                onClick={this.toggleCompareProperties}
                style={{ width: "130px", color: '#fff' }}
              >
                <img
                  src={require("assets/img/icons/blueTriangle.png")}
                  alt=""
                  style={{ width: "auto", height: "12px", marginRight: "10px" }}
                />
                <span className="bold-GalanoGrotesque">BACK</span>
              </button>
            </div>
          </div>
          <Row style={{ margin: "0" }}>
            {/* <div className="compare-home-wrapper" > */}
            <Col sm={12} md={12} lg={6}>
              <div className="compare-home-inner">
                <div className="compare-home-inner-left">
                  <CompareProperty propData={this.state.propertyCompare[0]} />
                </div>
              </div>
              </Col>
              <Col sm={12} md={12} lg={6}>
              <div className="compare-home-inner">
                <div className="compare-home-inner-right">
                  <CompareProperty propData={this.state.propertyCompare[1]} />
                </div>
              </div>
              </Col>
            {/* </div> */}
          </Row>
        </Aux>
      );
    }

    if (this.state.showCompare === false) {
      let propItems = this.state.favouriteProps
      .filter((house) => house.houseType === 'house')
      .map((p, index) => {

        // p.selected = p.selected ? p.selected : ( == p.id);
        return (
          <div key={index} className="property-scrolling-card">
            <Property
              status={p.status}
              image={p.image}
              bedrooms={p.bedrooms}
              bathrooms={p.bathrooms}
              description={p.description}
              slides={p.slidesSmall}
              floorplans={p.floorplanSlides}
              title={p.title}
              price={p.price}
              soPrice={p.soPrice}
              soPriceText={p.soPriceText}
              compareIndex={this.props.match ? this.props.match.params.plot : -1}
              compareChecked={this.handleCompareChecked}
              compareUnchecked={this.handleCompareUnchecked}
              checked={false}
              roomSizes={p.roomSizes}
              id={p.id}
              selected={p.selected}
              propertyIndex={p.propertyIndex}
              maxCompareSelected={this.state.maxCompareSelected}
              favourite={p.favourite}
              favouriteClick={this.favouriteClick}
              shareClick={this.shareClick}
            />
          </div>
        );
      });

      this.propertyItems = propItems;

      if (propItems.length === 0) {
        this.propertyItems = (
          <div
            style={{
              position: "fixed",
              top: "25vh",
              left: "3vw",
              width: "100vw",
              textAlign: "center",
            }}
          >
            <h1 className="bold-GalanoGrotesque" style={{ color: "#fff" }}>
            You haven’t chosen any favourites yet
            </h1>
          </div>
        );
      }

      this.compareSection = null;
      this.listingSection = (
        <Aux>
          {/*<div
            style={{
              position: "fixed",
              top: "45px",
              right: "39px",
              width: "200px",
            }}
          >
            <Link
              to="register-interest"
              className="lowerButton"
              style={{ color: "black", background: "#96c1d7", padding: "20px" }}
            >
              <span className="bold-GalanoGrotesque">REGISTER INTEREST</span>
            </Link>
          </div> */}
          {propItems.length > 0 ?
          <div
            style={{
              position: "absolute ",
              top: "145px",
              right: "15px",
              width: "45px",
              height: "45px",
              zIndex: 9,
              borderRadius: '50%',
              border: '2px solid white',
              padding: '5px',
              paddingLeft: '9px'
            }}
            onClick={() => this.clearFavourites()}
          >
            <img
              src={require("assets/img/icons/w.png")}
              alt=""
              style={{
                width: "23px",
                height: "auto",
                marginRight: "20px",
              }}
            />
          </div> : null}
          <Row style={{
              marginLeft: '0px',
              marginRight: '0px'}}>
                
            <Draggable cancel=".cancel-drag" bounds={{top: 0, left: -(this.propertyItems.length * 220), right: 0, bottom: 0}}  axis="x">
            <div
              ref={this.scrollRef}
              onScroll={this.listenScrollEvent.bind(this)}
              className="property-scrolling-wrapper"
              style={{ width: "100%", background: "#c3cdd2"}}
            >
              {this.propertyItems}
            </div>
           </Draggable>
            <div
              style={{
                position: "fixed",
                bottom: "30px",
                left: "30px",
                width: "400px",
              }}
            >
              <PlotFilters
                selectedHouseType={this.selectedHouseTypeChangedHandler}
                selectedBedrooms={this.selectedBedroomsChangedHandler}
                selectedPrice={this.selectedPriceChangedHandler}
                selectedMinPrice={this.selectedMinPriceChangedHandler}
                selectedArea={this.selectedAreaChangedHandler}
                sharedOwnershipChecked={this.sharedOwnershipChangedHandler}
                helpToBuyChecked={this.helpToBuyChangedHandler}
                resetControls={this.resetControlsHandler}
                selectedAvailability={this.selectedAvailabilityChangedHandler}
                houseType={this.state.filterHouseType}
                bedrooms={this.state.filterBedrooms}
                price={this.state.filterPrice}
                priceMin={this.state.filterMinPrice}
                area={this.state.filterArea}
                sharedOwnership={this.state.filterSharedOwnership}
                helpToBuy={this.state.filterHelpToBuy}
                availability={this.state.filterAvailability}
                propertyDropdpwnOptions={this.props.propertyDropdpwnOptions}
                priceDropdpwnOptions={this.props.priceDropdpwnOptions}
                priceMinDropdpwnOptions={this.props.priceMinDropdpwnOptions}
                areaDropdpwnOptions={this.props.areaDropdpwnOptions}
                bedroomsDropdpwnOptions={this.props.bedroomsDropdpwnOptions}
                availabilityDropdpwnOptions={this.props.availabilityDropdpwnOptions}
              />
            </div>
            
            <div
              style={{
                position: "fixed",
                bottom: "0px",
                right: "46px",
                width: "280px",
              }}
            >
              <button
                className="lowerButtonNarrow"
                onClick={this.toggleCompareProperties}
                style={{ width: "100%", margin: '0', background: '#34586b', color: '#fff', height: 'auto', padding: '10px 15px' }}
                disabled={!this.state.maxCompareSelected}
              >
                <img
                  src={require("assets/img/icons/compare.png")}
                  alt=""
                  style={{
                    width: "23px",
                    height: "auto",
                    marginRight: "20px",
                  }}
                />
                <img
                  src={require("assets/img/icons/compare.png")}
                  alt=""
                  style={{
                    width: "23px",
                    height: "auto",
                    marginRight: "20px",
                  }}
                />
                Compare homes
              </button>

            </div>
          </Row>
        </Aux>
      );
    }

    return (
      <Aux>
        <Fragment>
          <Container
            style={{
              maxWidth: "100vw",
              width: "100vw",
              //height: "calc(100vh - 150px)",
              height: "100vh",
              padding: "0px",
              marginTop: "130px 0px 0px",
              overflow: "hidden",
              backgroundColor: "#c3cdd2",
              // borderBottom: "5px solid #34586b",
              paddingBottom: "60px"
            }}
          >
            <div className="property-container">
              {this.compareSection}
              {this.listingSection}
            </div>
          </Container>
        </Fragment>
        <Modal
          isOpen={this.state.isModalOpen}
          //onAfterOpen={() => this.afterOpenModal()}
          onRequestClose={() => this.setState({ isModalOpen: false })}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={_subtitle => (subtitle = _subtitle)} style={{ marginTop: '0px', marginBottom: '30px', borderBottom: '1px solid lightgray', paddingBottom: '10px', fontSize: '22px', textAlign: 'center' }}>Share Plot</h2>
          <div onClick={() => this.setState({ isModalOpen: false })} className="closeShareModalIcon">
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </div>
            <TextInputField
              label="Your name"
              required
              value={this.state.yourName}
              onChange={e => this.setState({ yourName: e.target.value })}
              isInvalid={this.state.yourNameInvalid}
            />
            <TextInputField
              label="Your email"
              required
              value={this.state.yourEmail}
              onChange={e => this.setState({ yourEmail: e.target.value })}
              isInvalid={this.state.yourEmailInvalid}
            />
            <TextInputField
              label="Friend's name"
              value={this.state.friendName}
              onChange={e => this.setState({ friendName: e.target.value })}
            />
            <TextInputField
              label="Friend's email"
              value={this.state.friendEmail}
              onChange={e => this.setState({ friendEmail: e.target.value })}
            />
            <Pane paddingBottom={20}>
              <Button appearance="primary" iconAfter={ShareIcon} width={85} onClick={this.handleShare} style={{ background: 'rgb(55, 89, 107)', width: '100%', paddingLeft: '40%', marginBottom: '30px', height: '40px'}}>Send</Button>
              <h2 style={{ marginTop: '0px', marginBottom: '10px', paddingTop: '10px', paddingBottom: '10px', fontSize: '22px', textAlign: 'center', borderTop: '1px solid lightgray' }}>Copy link</h2>
              <CopyToClipboard text={this.state.value} onCopy={() => this.setState({copied: true})}>
                <Button appearance="primary" iconAfter={LinkIcon} width={85} onClick={this.handleCopyLink} style={{ background: 'rgb(55, 89, 107)', width: '100%', paddingLeft: '36%', height: '40px'}}>Copy Link</Button>
              </CopyToClipboard>
            </Pane>
            {this.state.showModalSubmitting ?
            <Pane display="flex" alignItems="center" justifyContent="center" height="100%" background="rgba(0,0,0,0.05)" style={{ position: 'absolute', width: '100%', top: '0' }}>
              <Spinner></Spinner>
            </Pane> : null}
        </Modal>
        {this.state.showFormSubmitting ?
        <Pane display="flex" alignItems="center" justifyContent="center" height="100%" background="rgba(0,0,0,0.2)" style={{ position: 'absolute', width: '100%', top: '0', zIndex: '99999', height: '100vh' }}>
          <Spinner></Spinner>
        </Pane> : null}
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    propertyDropdpwnOptions: state.ftr.propertyDropdpwnOptions,
    priceDropdpwnOptions: state.ftr.priceDropdpwnOptions,
    priceMinDropdpwnOptions: state.ftr.priceMinDropdpwnOptions,
    areaDropdpwnOptions: state.ftr.areaDropdpwnOptions,
    bedroomsDropdpwnOptions: state.ftr.bedroomsDropdpwnOptions,
    favouriteProps: state.prop.favouriteProps,
    availabilityDropdpwnOptions: state.ftr.availabilityDropdpwnOptions
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleFavouriteClick: (t,id) => dispatch(actionCreators.handleFavouriteClick(t,id))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Favourites);
