/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

// nodejs library that concatenates strings
import classnames from "classnames";
import "./LiveNavbar.css";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function LiveNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const goBack = () => {
    props.history.goBack();
  };

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  
  let navItems = props.items.map((p) => {
    return (
      <NavItem key={p.id}>
        <NavLink
          to={"/" + p.path}
          tag={Link}
          onClick={toggleNavbarCollapse}
          className={
            p.title === "- Development timeline"
              ? props.location.pathname === "/" + p.path
                ? "body-Kannada navTimeline navSelected"
                : "body-Kannada navTimeline"
              : p.title === "Terms & conditions"
              ? props.location.pathname === "/" + p.path
                ? "body-Kannada navTC navSelected"
                : "body-Kannada navTC"
              : p.title === "Privacy policy"
              ? props.location.pathname === "/" + p.path
                ? "body-Kannada navPP navSelected"
                : "body-Kannada navPP"
              : p.title === "- Exterior"
              ? props.location.pathname === "/" + p.path
                ? "body-Kannada navTimeline navSelected"
                : "body-Kannada navTimeline"
              : p.title === "- Interior"
              ? props.location.pathname === "/" + p.path
                ? "body-Kannada navTimeline navSelected"
                : "body-Kannada navTimeline"
              : p.title === "- Local area"
              ? props.location.pathname === "/" + p.path
                ? "body-Kannada navTimeline navSelected"
                : "body-Kannada navTimeline"
              : props.location.pathname === "/" + p.path
              ? "body-Kannada navSelected"
              : "body-Kannada"
          }
        >
          {p.title}
        </NavLink>
      </NavItem>
    );
  });

  let formButton = null;
  let backButton = null;
  let noFormButton = true;

  // if (props.location.pathname.startsWith('/plot-overview')) {
  //   backButton = (
  //     <div>
  //       <button
  //         className="lowerButtonNarrowTransparent"
  //         onClick={goBack}
  //         style={{color: '#37596b', fontSize: '20px'}}
  //       >
  //         <img
  //           src={require("assets/img/back-orange.svg")}
  //           alt=""
  //           style={{ width: "auto", height: "17px", marginRight: "10px" }}
  //         />
  //         <span className="bold-GalanoGrotesque">BACK</span>
  //       </button>
  //     </div>
  //   );
  // }

  const pathForTitle = props.location.pathname.replace('/', '');
  const itemForTitle = props.items.filter(obj => {
    return obj.path === pathForTitle
  });

  let showMenuButton = true;

  //if (
    // props.location.pathname.startsWith('/plot-overview')
    // props.location.pathname !== "/location" &&
    // props.location.pathname !== "/gallery" &&
    // props.location.pathname !== "/exterior" &&
    // props.location.pathname !== "/local-area" &&
    // props.location.pathname !== "/interior" &&
    // props.location.pathname !== "/homes-for-sale" &&
    // props.location.pathname !== "/register-interest"
  //) {
  //  showMenuButton = false;
    // noFormButton = false;

    // formButton = (
    //   <NavbarBrand
    //     data-placement="bottom"
    //     to={ '/share-property/' + props.location.pathname.replace('/plot-overview/', '') }
    //     target="_Top"
    //     tag={Link}
    //     style={{ color: "black", background: "#96c1d7", padding: "20px" }}
    //   >
    //     <span className="bold-GalanoGrotesque">SHARE PROPERTY</span>
    //   </NavbarBrand>
    // );
  //}
  return (
    <Navbar
      className={classnames(
        "fixed-top",
        (props.location.pathname.startsWith('/index') ? "navbar-home" : ""),
        noFormButton === true
          ? "navbar-transparent navbar-transparent-short"
          : navbarColor
      )}
      color-on-scroll="300"
      expand="lg"
    >
      {props.location !== undefined ? (
        <Container style={{ margin: "0px 20px", maxWidth: "100%" }}>
          <div className="navbar-translate">
            {showMenuButton && 
              <button
                aria-expanded={navbarCollapse}
                className={classnames("navbar-toggler navbar-toggler", {
                  toggled: navbarCollapse,
                })}
                onClick={toggleNavbarCollapse}
                style={{ zIndex: 800 }}
              >
                {(props.location.pathname === "/siteplan" || props.location.pathname.startsWith("/apartment-floors")) && !navbarCollapse ? (
                  <span className="navbar-toggler-bar bar1" />
                ) : (
                  <span className="navbar-toggler-bar bar1" />
                )}
                {(props.location.pathname === "/siteplan" || props.location.pathname.startsWith("/apartment-floors")) && !navbarCollapse ? (
                  <span className="navbar-toggler-bar bar2" />
                ) : (
                  <span className="navbar-toggler-bar bar2" />
                )}
                {(props.location.pathname === "/siteplan" || props.location.pathname.startsWith("/apartment-floors")) && !navbarCollapse ? (
                  <span className="navbar-toggler-bar bar3" />
                ) : (
                  <span className="navbar-toggler-bar bar3" />
                )}
              </button>
            }
            {backButton}
            {formButton}

            {/* {(props.location.pathname !== "/index" && window.header_logo != '') && 
              <Link to={"/index"}><div className="home-logo"><img src={window.header_logo} alt="" /></div></Link>
            } */}

          </div>

          {(props.location.pathname !== "/index" && itemForTitle.length > 0) && 
            <div className="page-title">{itemForTitle[0].title}</div>
          }
          {/* {props.location.pathname.indexOf("/apartment-floors") >-1 ?
            <div className="page-title">Apartment Block</div> : null
          } */}
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Link to={"/index"}><div className="home-logo"><img src={window.menu_logo} alt="" /></div></Link>
            <Nav navbar>{navItems}</Nav>
          </Collapse>
        </Container>
      ) : null}
    </Navbar>
  );
}

export default withRouter(LiveNavbar);
