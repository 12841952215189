import React from "react";

//images={this.state.galleryItems} openCarousel

const sevenImageSection = (props) => (
  <div className="row" style={{ padding: "0px", margin: "0px", width: "100%" }}>
    <div
      className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
      style={{ padding: "0px" }}
    >
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[0].id);
          }}
          src={props.images[0].src}
          style={{ width: "100%", height: "66vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[1].id);
          }}
          src={props.images[1].src}
          style={{ width: "100%", height: "34vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
    </div>
    <div
      className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
      style={{ padding: "0px" }}
    >
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[2].id);
          }}
          src={props.images[2].src}
          style={{ width: "100%", height: "33vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[3].id);
          }}
          src={props.images[3].src}
          style={{ width: "100%", height: "33vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[4].id);
          }}
          src={props.images[4].src}
          style={{ width: "100%", height: "34vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
    </div>
    <div
      className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
      style={{ padding: "0px" }}
    >
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[5].id);
          }}
          src={props.images[5].src}
          style={{ width: "100%", height: "33vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
      <div style={{ width: "100%" }}>
        <img
          onClick={() => {
            props.openCarousel(props.images[6].id);
          }}
          src={props.images[6].src}
          style={{ width: "100%", height: "67vh", objectFit: "cover" }}
          alt=""
          className="carouselImages"
        />
      </div>
    </div>
  </div>
);

export default sevenImageSection;
