import React, { Component } from "react";
import "./PropertyFilters.css";

class PropertyFilters extends Component {
  state = {
    selectedHouseType: "all",
    selectedBedrooms: "1",
    selectedPrice: 1000000,
    selectedArea: "0",
    sharedOwnershipChecked: false,
    helpToBuyChecked: false,
    showControls: false,
  };

  controlsSection = null;

  componentDidMount() {
    this.setState({
      selectedHouseType: this.props.houseType,
      selectedBedrooms: this.props.bedrooms,
      selectedPrice: this.props.price,
      selectedArea: this.props.area,
      sharedOwnershipChecked: this.props.sharedOwnership,
      helpToBuyChecked: this.props.helpToBuy,
    });
  }

  houseTypeChangedHandler = (event) => {
    this.setState({ selectedHouseType: event.target.value });
    this.props.selectedHouseType(event.target.value);
  };

  bedroomsChangedHandler = (event) => {
    this.setState({ selectedBedrooms: event.target.value });
    this.props.selectedBedrooms(event.target.value);
  };

  priceChangedHandler = (event) => {
    this.setState({ selectedPrice: event.target.value });
    this.props.selectedPrice(event.target.value);
  };

  areaChangedHandler = (event) => {
    this.setState({ selectedArea: event.target.value });
    this.props.selectedArea(event.target.value);
  };

  handleSharedOwnershipCheckboxChange = (event) => {
    this.setState({ sharedOwnershipChecked: event.target.checked });
    this.props.sharedOwnershipChecked(event.target.checked);
  };

  handleHelpToBuyCheckboxChange = (event) => {
    this.setState({ helpToBuyChecked: event.target.checked });
    this.props.helpToBuyChecked(event.target.checked);
  };

  toggleMapControls = (event) => {
    this.setState({ showControls: !this.state.showControls });
  };

  resetFilters = (event) => {
    this.setState({
      selectedHouseType: "all",
      selectedBedrooms: "1",
      selectedPrice: 1000000,
      selectedArea: "0",
      sharedOwnershipChecked: false,
      helpToBuyChecked: false,
    });

    this.props.resetControls();
  };

  render() {
    return (
      <div style={{ position: "fixed", bottom: "0", left: "46px", zIndex: "200", overflow: "hidden", width: "350px" }} >
        <div onClick={this.toggleMapControls} style={{ backgroundColor: "#58b785", padding: "20px", width: "100%", fontSize: '12px', fontWeight: 'bold'}}>
          <img
            className="actionContainer"
            onClick={this.toggleMapControls}
            src={require("assets/img/icons/icon_filter.svg")}
            alt=""
            style={{ width: "25px", height: "auto", marginRight: "10px", verticalAlign: "middle" }}
          />
          <span
            className="actionText"
            onClick={this.toggleMapControls}
            style={{ fontSize: "25px", color: "#fff", marginLeft: "10px", verticalAlign: "middle", fontFamily: "HelveticaNeue, Arial, sans-serif", fontWeight: "bold",}}
          >
            Filter plots
          </span>
          <span
              onClick={this.toggleMapControls}
              style={{
                color: "#fff",
                position: "absolute",
                right: "25px",
                cursor: "pointer",
                top: "25px"
              }}
            >
              <img src={require("assets/img/filter-close.svg")} alt="" style={{ width: "30px", }} />
            </span>
          
        </div>
        
        <div className={(this.state.showControls === true ? 'activeFilter' : 'inactiveFilter') + ' filterPropertyViewport'} style={{ backgroundColor: "#fff" }}>

          { /* <div style={{ padding: "0 20px", margin: "40px 0px 15px 0px" }}>
            <label
              className="body-GalanoGrotesque"
              style={{ color: "#fff", fontSize: "16px" }}
            >
              House type
            </label>
            <select
              onChange={(event) =>
                this.houseTypeChangedHandler(event, "input_houseType")
              }
              id="input_houseType"
              name="input_houseType"
              className="form-control"
              defaultValue={this.state.selectedHouseType}
            >
              {this.props.propertyDropdpwnOptions.map((p) => (
                <option
                  key={p.id}
                  value={p.value}
                >
                  {p.display}
                </option>
              ))}
            </select>
          </div> */ }

          <div style={{ padding: '15px', margin: '0px'}}>
            <label
              className="body-GalanoGrotesque"
              style={{ color: "#A2D9F1", fontSize: "16px" }}
            >
              Bedrooms
            </label>
            <select
              onChange={(event) =>
                this.bedroomsChangedHandler(event, "input_bedrooms")
              }
              id="input_bedrooms"
              name="input_bedrooms"
              className="form-control"
              defaultValue={this.state.selectedBedrooms}
              style={{backgroundColor: "#EFEFEF"}}
            >
              {this.props.bedroomsDropdpwnOptions.map((p) => (
                <option
                  key={p.id}
                  value={p.value}
                >
                  {p.display}
                </option>
              ))}
            </select>
          </div>

          <div style={{ padding: '15px', margin: '0px'}}>
            <label
              className="body-GalanoGrotesque"
              style={{ color: "#A2D9F1", fontSize: "16px" }}
            >
              Maximum price
            </label>
            <select
              onChange={(event) =>
                this.priceChangedHandler(event, "input_price")
              }
              id="input_price"
              name="input_price"
              className="form-control"
              defaultValue={this.state.selectedPrice}
              style={{backgroundColor: "#EFEFEF"}}
            >
              {this.props.priceDropdpwnOptions.map((p) => (
                <option
                  key={p.id}
                  value={p.value}
                >
                  {p.display}
                </option>
              ))}
            </select>
          </div>

          <div style={{ padding: '15px', margin: '0px'}}>
            <label
              className="body-GalanoGrotesque"
              style={{ color: "#A2D9F1", fontSize: "16px" }}
            >
              Minimum square footage
            </label>
            <select
              onChange={(event) => this.areaChangedHandler(event, "input_area")}
              id="input_area"
              name="input_area"
              className="form-control"
              defaultValue={this.state.selectedArea}
              style={{backgroundColor: "#EFEFEF"}}
            >
              {this.props.areaDropdpwnOptions.map((p) => (
                <option
                  key={p.id}
                  value={p.value}
                >
                  {p.display}
                </option>
              ))}
            </select>
          </div>

          <div style={{ padding: "20px 20px 0", margin: "0px" }}>
            <label
              className="body-GalanoGrotesque"
              style={{ color: "#A2D9F1", fontSize: "16px", marginBottom: '0px' }}
            >
              Available schemes
            </label>
            <span className="customcheck">
              <label
                className="customcheck body-GalanoGrotesque"
                style={{ marginLeft: "35px", fontSize: "16px", color: "#A2D9F1", marginBottom: '0px' }}
              >
                {" "}
                Shared Ownership
                <input
                  type="checkbox"
                  checked={this.state.sharedOwnershipChecked}
                  onChange={this.handleSharedOwnershipCheckboxChange}
                  className="customcheck"
                  name="entertainment"
                  value="yes"
                />
                <span className="customcheck-target"></span>
              </label>
            </span>
          </div>
          {/* <div style={{ padding: "0px 20px", margin: "-10px 0" }}>
            <span className="customcheck">
              <label
                className="customcheck body-GalanoGrotesque"
                style={{ marginLeft: "35px", fontSize: "16px", color: "#A2D9F1" }}
              >
                {" "}
                Help to Buy
                <input
                  type="checkbox"
                  checked={this.state.helpToBuyChecked}
                  onChange={this.handleHelpToBuyCheckboxChange}
                  className="customcheck"
                  name="shopping"
                  value="yes"
                />
                <span className="customcheck-target"></span>
              </label>
            </span>
          </div> */}

          <div style={{ padding: '15px', margin: '25px 0px', textAlign: 'left' }}>
            <p
              className="actionText"
              onClick={this.resetFilters}
              style={{ textTransform: "uppercase", textDecoration: "underline", fontSize: "14px", fontWeight: 'bold' }}
            >
              <span className="body-GalanoGrotesque"  style={{color: '#A2D9F1'}}>Reset Filters</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertyFilters;
