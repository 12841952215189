import React from "react";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./ReactCarouselFloorplan.css"

class ReactCarouselFloorPlanPO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onchange = this.onchange.bind(this);
  }

  onchange(value) {
    this.setState({ value });
  }

  componentDidMount() {}

  componentWillMount() {}

  render() {
    return (
      <div
        style={{
          maxWidth: "456px",
          // height: "44vh",
          display: "flex",
          justifyContent: "center",
          flexFlow: "row",
          alignContent: "center",
          textAlign: "center",
          width: '100%',
          margin: '0 auto'
        }}
      >
        {this.props.slides.length > 1 ?
        <Carousel
          slidesPerPage={1}
          value={this.state.value}
          onChange={this.onchange}
          keepDirectionWhenDragging
          style={{ width: '100%'}}
        >
          {this.props.slides.map((item) => {
            return (
              <div key={item.id} style={{ width: "100%" }}>
                <img
                  style={{
                    borderRadius: "0px",
                    objectFit: "cover",
                    padding: '0px',
                    width: "100%",
                    padding: "20px"
                  }}
                  src={item.backgroundImage}
                  alt={item.altText}
                />
                <div className="imageFloorplanTextOverlay">
                  {item.caption}
                </div>
              </div>
            );
          })}
        </Carousel>
        :
        this.props.slides.map((item) => {
          return (
            <div key={item.id} style={{ width: "100%", }}>
              <img
                style={{
                  borderRadius: "0px",
                  objectFit: "cover",
                  padding: '0px'
                }}
                src={item.backgroundImage}
                alt={item.altText}
                className="imageFloorplanWidth"
              />
              <div className="imageFloorplanTextOverlay">
                {item.caption}
              </div>
            </div>
          );
        })}

        {this.props.slides.length > 1 && 
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              width: "100%",
            }}
          >
            <Dots
              value={this.state.value} 
              onChange={this.onchange}
              number={this.props.slides.length}
            />
          </div>
        }
      </div>
    );
  }
}
export default ReactCarouselFloorPlanPO;
