import React from "react";

import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";

class BuyingScheme extends React.Component {
  render() {
    return (
      <>
        <Row style={{ margin: '0px' }}>
            <Col sm={12} id="carousel" style= {{ padding: '0px' }}>
              <Container style={{ margin: '0px', maxWidth: '100%'}}>
                <Row>
                  <Col className="page-header ml-auto mr-auto" style={{ padding: '0px'}}>
                    {this.props.buyingScheme && 
                      <Card className="page-carousel no-radius buying-scheme" style={{ height: '100%', marginBottom: '0px'}}>
                        <Row style={{ marginLeft: '0px', marginRight: '0px'}}>
                          <Col md={6} sm={12}>
                            <div className="left-side">
                              <Row>
                                {/* <Col sm={2} id="carousel" style= {{ padding: '0px' }}>
                                  <img src={this.props.buyingScheme.lead_icon} alt="" />
                                </Col> */}
                                <Col sm={12} id="carousel" style= {{ padding: '0px' }}>
                                  <h1>{this.props.buyingScheme.lead_text}</h1>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} id="carousel" style= {{ padding: '0px' }}>
                                  <img className="leftImage" src={this.props.buyingScheme.main_image} alt="" />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={6} sm={12}>
                          <div className="buying-scheme--items">
                            <Row>
                            {this.props.buyingScheme.items.map((item, index) => {
                              return (
                                  <Col sm={12}>
                                      <div className="item" key={index}>
                                        <h2 style={{ marginTop: '0px'}}>{ item.title }</h2>
                                        <hr></hr>
                                        { item.content }
                                        { item.test_field }
                                      </div>
                                    </Col>
                              );
                            })}
                            </Row>
                          </div>
                          </Col>
                          <Row style={{ marginTop: '20px', marginLeft: '0px', marginRight: '0px'}}>
                            <Col sm={12} style= {{ padding: '30px', textAlign: 'center', fontSize: '18px' }}>
                            { this.props.buyingScheme.footer_text }
                            </Col>
                          </Row>
                        </Row>
                      </Card>
                    }
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    buyingScheme: state.sch.buyingScheme
  };
}

export default connect(mapStateToProps)(BuyingScheme);
