const domain = window.document.domain;

let imageUrl = "";

switch (domain) {
	case "broadmeadowparkapp.co.uk":
		imageUrl = 'https://broadmeadowparkapp.co.uk';
		break;
	case "broadmeadowpark.focus-pluto.co.uk":
		imageUrl = 'https://broadmeadowpark.focus-pluto.co.uk/';
		break;
	default:
		imageUrl = 'https://broadmeadowpark.focus-pluto.co.uk/';
		break;
}

window.imageUrl = imageUrl;
window.logo = '';
window.header_logo = '';
