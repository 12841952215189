import React, { Fragment, Component } from "react";
import SevenImageSection from "./GalleryPageSections/SevenImageSection";
import SixImageSection from "./GalleryPageSections/SixImageSection";
import FiveImageSection from "./GalleryPageSections/FiveImageSection";
import FourImageSection from "./GalleryPageSections/FourImageSection";
import ThreeImageSection from "./GalleryPageSections/ThreeImageSection";
import TwoImageSection from "./GalleryPageSections/TwoImageSection";
import OneImageSection from "./GalleryPageSections/OneImageSection";
import {connect} from 'react-redux';
import ReactCarouselGallery from "../components/UI/ReactCarousel/ReactCarouselGallery";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

class GalleryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");

  }

  render() {
    //if (this.props.sitePages.show_background_video) {
      return (
        <div className="page-header page-header--home" data-parallax={true}>
        <video
        autoPlay
        muted
        loop
        className="videoSize"
        controls
      >
        <source src={require("assets/video/BroadMeadow.mp4")} type="video/mp4" />
      </video>
        </div>
        
      )
    //} else return null
  }
}

const mapStateToProps = state => {
  return {
    sitePages: state.pag.sitePages
  };
}

export default connect(mapStateToProps)(GalleryPage);
